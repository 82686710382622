import { customElement, property, query } from "lit/decorators.js";
import createTemplate from "./html/dms-order-action-create.html";
import createTemplateLocal from "./html/dms-order-action-create-local.html";
import deliveredTemplate from "./html/dms-order-action-delivered.html";
import recivedTemplate from "./html/dms-order-action-recieved.html";
import recivedTemplateLocal from "./html/dms-order-action-recieved-local.html";
import dispatchedTemplate from "./html/dms-order-action-dispatch.html";
import rejectTemplate from "./html/dms-order-action-reject.html";
import pickupTemplate from "./html/dms-order-action-pickup.html";
import { OrderModel, OrderStatus } from "../../models/order-model";
import { store } from "../../store/dms-store";
import { LightElement } from "../../common/light-element";
import { Modal, initFlowbite } from "flowbite";
import { orderActions } from "../../store/order-store";
import { DeliveryManagementSectionMessage } from "../dms-section-message/dms-section-message";
import { DMSInternalBaseClass } from "../../common/dsm-internal-base-class";
import { SHOW_HIDE_SPINNER } from "../../constants/events";
import { Router } from "@vaadin/router";
import { OrderService } from "../../service/order-service/order-service";
import { inject } from "../../utility/decorators/decorators";
import { SERVICE_PROVIDER_ID } from "../../utility/service-provider/service-provider-utility";
import { DMSDataService } from "../../service/dms-data-service/dms-data-service";
import { AccessLevel } from "../../models/user-model";

interface OrderState {
  [key: string]: { isValid: boolean } | null | undefined;
}

@customElement("dms-order-actions")
export class DeliveryManagementOrderActions extends DMSInternalBaseClass {
  private pickupImage: File;
  private busImage: File;
  private dispatchImage: File;
  private recievedImage: File;
  private deliveryImage: File;

  @inject(SERVICE_PROVIDER_ID.ORDER_SERVICE)
  orderService: OrderService;
  @inject(SERVICE_PROVIDER_ID.DMS_DATA_SERVICE)
  private dmsDataService: DMSDataService;

  @property()
  orderId: string;

  private orderStatus: OrderStatus;

  private fields: any;

  private _pickupImageEventHandler = this.pickupImageEventHandler.bind(this);
  private _busImageEventHandler = this.busImageEventHandler.bind(this);
  private _dispatchImageEventHandler =
    this.dispatchImageEventHandler.bind(this);
  private _recievedImageEventHandler =
    this.recievedImageEventHandler.bind(this);
  private _deliveryImageEventHandler =
    this.deliveryImageEventHandler.bind(this);

  constructor() {
    super();
    this.eventBus.subscribe(
      "busImage-file-dispatched",
      this._busImageEventHandler
    );
    this.eventBus.subscribe(
      "pickupImage-file-dispatched",
      this._pickupImageEventHandler
    );
    this.eventBus.subscribe(
      "dispatchImage-file-dispatched",
      this._dispatchImageEventHandler
    );
    this.eventBus.subscribe(
      "recievedImage-file-dispatched",
      this._recievedImageEventHandler
    );
    this.eventBus.subscribe(
      "deliveryImage-file-dispatched",
      this._deliveryImageEventHandler
    );
  }

  private busImageEventHandler(e: any) {
    this.busImage = e.detail.file;
  }
  private dispatchImageEventHandler(e: any) {
    this.dispatchImage = e.detail.file;
  }
  private recievedImageEventHandler(e: any) {
    this.recievedImage = e.detail.file;
  }
  private deliveryImageEventHandler(e: any) {
    this.deliveryImage = e.detail.file;
  }
  private pickupImageEventHandler(e: any) {
    this.pickupImage = e.detail.file;
  }

  private async handleDispatchOrder() {
    this.setErrorName(this.fields);
    if (this.checkAllValidName(store.getState()?.Order, this.fields)) {
      try {
        const status = this.getResolvedStatus(
          (store.getState().Order as OrderModel).orderStatus
        );
        this.eventBus.publish(SHOW_HIDE_SPINNER, { detail: { visible: true } });
        const updatedOrder = await this.orderService.updateSingleOrder(
          this.orderId,
          this.fields,
          status
        );
        if (this.pickupImage) {
          await this.orderService.uploadImage(
            this.pickupImage,
            this.orderId,
            "pickup"
          );
        }
        if (this.busImage) {
          await this.orderService.uploadImage(
            this.busImage,
            this.orderId,
            "bus"
          );
        }
        if (this.dispatchImage) {
          await this.orderService.uploadImage(
            this.dispatchImage,
            this.orderId,
            "dispatch"
          );
        }
        if (this.recievedImage) {
          await this.orderService.uploadImage(
            this.recievedImage,
            this.orderId,
            "recieved"
          );
        }
        if (this.deliveryImage) {
          await this.orderService.uploadImage(
            this.deliveryImage,
            this.orderId,
            "delivered"
          );
        }
        const elModal = new Modal(document.getElementById("extralarge-modal"));
        elModal.hide();
        Router.go(
          `/order-detail/${this.orderId}/${updatedOrder.order.orderStatus}`
        );
      } catch (error) {
        console.log(error);
      } finally {
        this.eventBus.publish(SHOW_HIDE_SPINNER, {
          detail: { visible: false },
        });
      }
    }
  }

  private async rejectOrder() {
    try {
      this.eventBus.publish(SHOW_HIDE_SPINNER, { detail: { visible: true } });
      const updatedOrder = await this.orderService.updateSingleOrder(
        this.orderId,
        [],
        OrderStatus.REJECTED
      );
      const elModal = new Modal(document.getElementById("popup-modal"));
      elModal.hide();
      Router.go(
        `/order-detail/${this.orderId}/${updatedOrder.order.orderStatus}`
      );
    } catch (error) {
      console.log(error);
    } finally {
      this.eventBus.publish(SHOW_HIDE_SPINNER, {
        detail: { visible: false },
      });
    }
  }

  private getResolvedStatus(currentStatus: OrderStatus) {
    if (currentStatus === OrderStatus.CREATED) {
      return OrderStatus.PICKED;
    }
    if (currentStatus === OrderStatus.PICKED) {
      return OrderStatus.DISPATCHED;
    }
    if (currentStatus === OrderStatus.DISPATCHED) {
      return OrderStatus.RECEIVED;
    }
    if (currentStatus === OrderStatus.RECEIVED) {
      return OrderStatus.DELIVERED;
    }
    return OrderStatus.NONE;
  }

  private checkAllValidName(obj: any, fieldNames: string[]): boolean {
    for (const fieldName of fieldNames) {
      const prop = obj[fieldName];
      if (prop?.hasOwnProperty("isValid") && !prop.isValid) {
        return false;
      }
    }
    return true;
  }

  private setErrorName(fieldNames: string[]) {
    const orderState: OrderState = store.getState()?.Order;

    if (!orderState) {
      return; // If Order state is not available, exit the function
    }

    fieldNames.forEach((fieldName) => {
      const prop = orderState[fieldName];
      if (prop != null && typeof prop === "object" && "isValid" in prop) {
        const errorElement = document.getElementById(
          `${fieldName}-Error`
        ) as DeliveryManagementSectionMessage;
        if (errorElement) {
          errorElement.visible = !prop.isValid;
        }
      }
    });
  }

  connectedCallback(): void {
    super.connectedCallback();
    this.orderStatus = (store.getState().Order as OrderModel).orderStatus;
  }

  firstUpdated(): void {
    initFlowbite();
  }

  shouldUpdate(): boolean {
    const userAccess = this.dmsDataService.getDMSData()?.user?.accessLevel;
    if(userAccess === AccessLevel.Admin || userAccess === AccessLevel.Super_Admin){
      return true;
    }
    return false; 
  }

  render() {
    switch (this.orderStatus) {
      case OrderStatus.CREATED: {
        this.fields = [
          "pickupRemark",
          "pickupImage",
          "pickupDate",
          "pickupTime",
        ];
        return pickupTemplate.call(this);
      }
      case OrderStatus.PICKED: {
        if (
          (store.getState().Order as OrderModel).dispatchMedium.value == "Bus"
        ) {
          this.fields = [
            "busName",
            "busNumber",
            "busDriverNumber",
            "busImage",
            "dispatchImage",
            "eta",
            "dispatchTime",
            "dispatchDate"
          ];
          return createTemplate.call(this);
        } else {
          this.fields = [
            "localDeliveryRemark",
            "dispatchImage",
            "dispatchTime",
            "dispatchDate",
          ];
          return createTemplateLocal.call(this);
        }
      }
      case OrderStatus.DISPATCHED: {
        this.fields = [
          "recieveRemark",
          "recievedImage",
          "recieveTime",
          "recieveDate",
        ];
        return dispatchedTemplate.call(this);
      }
      case OrderStatus.RECEIVED: {
        if (
          (store.getState().Order as OrderModel).dispatchMedium.value == "Bus"
        ) {
          this.fields = [
            "deliveryRemark",
            "deliveryImage",
            "deliverTime",
            "deliverDate",
            "remoteRecieverName",
          ];
          return recivedTemplate.call(this);
        } else {
          this.fields = [
            "deliveryRemark",
            "deliveryImage",
            "deliverTime",
            "deliverDate",
            "deliveryPersonName",
          ];
          return recivedTemplateLocal.call(this);
        }
      }
      case OrderStatus.DELIVERED: {
        return deliveredTemplate.call(this);
      }
      case OrderStatus.REJECTED: {
        return rejectTemplate.call(this);
      }
    }
  }
}
