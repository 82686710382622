import { customElement, property, query } from "lit/decorators.js";
import { DMSInternalBaseClass } from "../../common/dsm-internal-base-class";
import template from "./html/dms-edit-users.html";
import { SHOW_HIDE_ERROR, SHOW_HIDE_SPINNER } from "../../constants/events";
import { BeforeEnterObserver, Router, RouterLocation } from "@vaadin/router";
import { AuthService } from "../../service/auth/auth-service";
import { inject } from "../../utility/decorators/decorators";
import { SERVICE_PROVIDER_ID } from "../../utility/service-provider/service-provider-utility";
import { DMSHubService } from "../../service/hub-service/hubs-service";
import { html } from "lit";
import { DMSDataService } from "../../service/dms-data-service/dms-data-service";
import { DMSCategoryService } from "../../service/category-service/category-service";

@customElement("dms-edit-users")
export class DeliveryManagementEditUsers extends DMSInternalBaseClass   implements BeforeEnterObserver{
  @query(`#name`) nameInput: HTMLInputElement;
  @query(`#email`) emailInput: HTMLInputElement;
  @query(`#userRole`) roleSelect: HTMLSelectElement;
  @query(`#createUser-Error`) createUserError: any;
  @query(`#hubList`) hubListSelect: HTMLSelectElement;
  @query(`#selectedHubsTextArea`) selectedHubsTextArea: HTMLTextAreaElement;
  @query(`#categoryList`) categoryListSelect: HTMLSelectElement;
  @query(`#selectedCategoriesTextArea`) selectedCategoriesTextArea: HTMLTextAreaElement;

  @inject(SERVICE_PROVIDER_ID.AUTH_SERVICE)
  private authService: AuthService;
  @inject(SERVICE_PROVIDER_ID.HUBS_SERVICE)
  hubService: DMSHubService;
  @inject(SERVICE_PROVIDER_ID.DMS_DATA_SERVICE)
  private dmsDataService: DMSDataService;
  @inject(SERVICE_PROVIDER_ID.CATEGORY_SERVICE)
  categoryService: DMSCategoryService;

  @property({ type: Boolean }) dataLoaded = false;
  @property()
  userId: string;

  private user:any;

  private hubList: any[] = [];
  private categoryList :any[] = [];

  constructor() {
    super();
  }

  async connectedCallback() {
    super.connectedCallback();
    await this.fetchHubsAndUser();
    this.handleRoleChange();
    this.hubListSelect.addEventListener('dblclick', this.updateTextAreaHubs.bind(this));
    this.categoryListSelect.addEventListener('dblclick', this.updateTextAreaCategory.bind(this));
  }

  private updateTextAreaHubs(e: any) {
    const selectedOption = e.target.value;
    const currentText = this.selectedHubsTextArea.value.trim();

    // Convert current text area value to an array of hub names
    const currentHubs = currentText ? currentText.split(',').map(hub => hub.trim()) : [];

    // Check if the selected option is already in the current list
    if (!currentHubs.includes(selectedOption)) {
      if (currentText) {
        this.selectedHubsTextArea.value = currentText + ', ' + selectedOption;
      } else {
        this.selectedHubsTextArea.value = selectedOption;
      }
    }
  }

  private updateTextAreaCategory(e: any) {
    const selectedOption = e.target.value;
    const currentText = this.selectedCategoriesTextArea.value.trim();

    // Convert current text area value to an array of hub names
    const currentCategories = currentText ? currentText.split(',').map(hub => hub.trim()) : [];

    // Check if the selected option is already in the current list
    if (!currentCategories.includes(selectedOption)) {
      if (currentText) {
        this.selectedCategoriesTextArea.value = currentText + ', ' + selectedOption;
      } else {
        this.selectedCategoriesTextArea.value = selectedOption;
      }
    }
  }

  shouldUpdate(): boolean {
    return this.dataLoaded;
  }

  firstUpdated(): void {
    this.nameInput.value = this.user.name;
    this.emailInput.value = this.user.email;
    this.roleSelect.value = this.user.accessLevel;
    this.selectedHubsTextArea.value = (this.user.mappedHub || []).join(', ');
    this.selectedCategoriesTextArea.value = (this.user.mappedCategories || []).join(', ');
    //this.hubListSelect.value = this.user.mappedHub;
  }

  private async fetchHubsAndUser() {
    try {
      this.eventBus.publish(SHOW_HIDE_SPINNER, { detail: { visible: true } });
      const response = await this.dmsDataService.fetchSingleUser(this.userId);
      const userData = response?.user;
      this.user = userData;
      const responseNew = await this.hubService.getHubsData();
      const hubData = responseNew?.hubs;
      this.hubList = hubData.sort((a: any, b: any) => a.hubName.localeCompare(b.hubName));
      const responseNewCategory = await this.categoryService.getCategoryData();
      const categoryData = responseNewCategory?.categories;
      this.categoryList =  categoryData.sort((a: any, b: any) => a.orderCategory.localeCompare(b.orderCategory));
      this.dataLoaded = true;
    } catch (error) {
      console.log(error);
    } finally {
      this.eventBus.publish(SHOW_HIDE_SPINNER, {
        detail: { visible: false },
      });
    }
  }

  async onBeforeEnter(location: RouterLocation) {
    const userId = location.params.userId as string;
    this.userId = userId;
  }

  private handleRoleChange() {
    const selectedRole = this.roleSelect.value;
    if (selectedRole === 'Super_Admin' || selectedRole === 'Admin') {
      this.hubListSelect.value = '';
      this.hubListSelect.disabled = true;
      this.selectedHubsTextArea.value = '';
      this.selectedHubsTextArea.disabled = true;
      this.categoryListSelect.value = '';
      this.categoryListSelect.disabled = true;
      this.selectedCategoriesTextArea.value = '';
      this.selectedCategoriesTextArea.disabled = true;
    } else {
      this.hubListSelect.disabled = false;
      this.selectedHubsTextArea.disabled = false;
      this.categoryListSelect.disabled = false;
      this.selectedCategoriesTextArea.disabled = false;
    }
  }

  private async handleSubmit(event: Event) {
    this.createUserError.visible = false;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const selectedHubs = this.selectedHubsTextArea.value.split(',').map(hub => hub.trim()).filter(hub => hub);
    const selectedCategories = this.selectedCategoriesTextArea.value.split(',').map(category => category.trim()).filter(category => category);
    if (
      !this.nameInput.value ||
      !this.emailInput.value ||
      this.roleSelect.value === "Choose a role" ||
      (this.hubListSelect.disabled === false && selectedHubs.length === 0) ||
      (this.categoryListSelect.disabled === false && selectedCategories.length === 0) ||
      !emailRegex.test(this.emailInput.value)
    ) {
      this.createUserError.visible = true;
      console.error("Form validation failed.");
      return;
    }
    try {
      this.eventBus.publish(SHOW_HIDE_SPINNER, { detail: { visible: true } });
      await this.dmsDataService.updateSingleUser(this.userId,
        this.nameInput.value,
        this.emailInput.value,
        this.roleSelect.value,
        selectedHubs,
        selectedCategories
      );
        Router.go("/users");
      
    } catch (error) {
      this.createUserError.visible = true;
    } finally {
      this.eventBus.publish(SHOW_HIDE_SPINNER, { detail: { visible: false } });
    }
  }

  private renderOptionsValue(){
    return html`${this.hubList.map(hub => html`<option value="${hub.hubName}">${hub.hubName}</option>`)}`;
  }

  private renderCategoryValue(){
    return html`${this.categoryList.map(category => html`<option value="${category.orderCategory}">${category.orderCategory}</option>`)}`;
  }

  render() {
    return template.call(this);
  }
}
