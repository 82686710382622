import { customElement, property, query } from "lit/decorators.js";
import { DMSInternalBaseClass } from "../../common/dsm-internal-base-class";
import template from "./html/dms-create-users.html";
import { SHOW_HIDE_ERROR, SHOW_HIDE_SPINNER } from "../../constants/events";
import { Router } from "@vaadin/router";
import { AuthService } from "../../service/auth/auth-service";
import { inject } from "../../utility/decorators/decorators";
import { SERVICE_PROVIDER_ID } from "../../utility/service-provider/service-provider-utility";
import { DMSHubService } from "../../service/hub-service/hubs-service";
import { html } from "lit";
import { DMSCategoryService } from "../../service/category-service/category-service";

@customElement("dms-create-users")
export class DeliveryManagementCreateUsers extends DMSInternalBaseClass {
  @query(`#name`) nameInput: HTMLInputElement;
  @query(`#email`) emailInput: HTMLInputElement;
  @query(`#password`) passwordInput: HTMLInputElement;
  @query(`#repeat-password`) repeatPasswordInput: HTMLInputElement;
  @query(`#userRole`) roleSelect: HTMLSelectElement;
  @query(`#createUser-Error`) createUserError: any;
  @query(`#hubList`) hubListSelect: HTMLSelectElement;
  @query(`#selectedHubsTextArea`) selectedHubsTextArea: HTMLTextAreaElement;
  @query(`#categoryList`) categoryListSelect: HTMLSelectElement;
  @query(`#selectedCategoriesTextArea`) selectedCategoriesTextArea: HTMLTextAreaElement;

  @inject(SERVICE_PROVIDER_ID.AUTH_SERVICE)
  private authService: AuthService;
  @inject(SERVICE_PROVIDER_ID.HUBS_SERVICE)
  hubService: DMSHubService;
  @inject(SERVICE_PROVIDER_ID.CATEGORY_SERVICE)
  categoryService: DMSCategoryService;

  @property({ type: Boolean }) dataLoaded = false;

  private hubList: any[] = [];
  private categoryList :any[] = [];

  constructor() {
    super();
  }

  async connectedCallback() {
    super.connectedCallback();
    await this.fetchHubsAndCategories();
    this.handleRoleChange();
    this.hubListSelect.addEventListener('dblclick', this.updateTextAreaHubs.bind(this));
    this.categoryListSelect.addEventListener('dblclick', this.updateTextAreaCategory.bind(this));
  }

  private updateTextAreaHubs(e: any) {
    const selectedOption = e.target.value;
    const currentText = this.selectedHubsTextArea.value.trim();

    // Convert current text area value to an array of hub names
    const currentHubs = currentText ? currentText.split(',').map(hub => hub.trim()) : [];

    // Check if the selected option is already in the current list
    if (!currentHubs.includes(selectedOption)) {
      if (currentText) {
        this.selectedHubsTextArea.value = currentText + ', ' + selectedOption;
      } else {
        this.selectedHubsTextArea.value = selectedOption;
      }
    }
  }

  private updateTextAreaCategory(e: any) {
    const selectedOption = e.target.value;
    const currentText = this.selectedCategoriesTextArea.value.trim();

    // Convert current text area value to an array of hub names
    const currentCategories = currentText ? currentText.split(',').map(hub => hub.trim()) : [];

    // Check if the selected option is already in the current list
    if (!currentCategories.includes(selectedOption)) {
      if (currentText) {
        this.selectedCategoriesTextArea.value = currentText + ', ' + selectedOption;
      } else {
        this.selectedCategoriesTextArea.value = selectedOption;
      }
    }
  }

  shouldUpdate(): boolean {
    return this.dataLoaded;
  }

  private async fetchHubsAndCategories() {
    try {
      this.eventBus.publish(SHOW_HIDE_SPINNER, { detail: { visible: true } });
      const response = await this.hubService.getHubsData();
      const hubData = response?.hubs;
      this.hubList = hubData.sort((a: any, b: any) => a.hubName.localeCompare(b.hubName));
      const responseNew = await this.categoryService.getCategoryData();
      const categoryData = responseNew?.categories;
      this.categoryList =  categoryData.sort((a: any, b: any) => a.orderCategory.localeCompare(b.orderCategory));
      this.dataLoaded = true;
    } catch (error) {
      console.log(error);
    } finally {
      this.eventBus.publish(SHOW_HIDE_SPINNER, {
        detail: { visible: false },
      });
    }
  }

  private handleRoleChange() {
    const selectedRole = this.roleSelect.value;
    if (selectedRole === 'Super_Admin' || selectedRole === 'Admin') {
      this.hubListSelect.value = '';
      this.hubListSelect.disabled = true;
      this.selectedHubsTextArea.value = '';
      this.selectedHubsTextArea.disabled = true;
      this.categoryListSelect.value = '';
      this.categoryListSelect.disabled = true;
      this.selectedCategoriesTextArea.value = '';
      this.selectedCategoriesTextArea.disabled = true;
    } else {
      this.hubListSelect.disabled = false;
      this.selectedHubsTextArea.disabled = false;
      this.categoryListSelect.disabled = false;
      this.selectedCategoriesTextArea.disabled = false;
    }
  }

  // private async handleSubmit(event: Event) {
  //   this.createUserError.visible = false;
  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //   if (
  //     !this.nameInput.value ||
  //     !this.emailInput.value ||
  //     !this.passwordInput.value ||
  //     !this.repeatPasswordInput.value ||
  //     this.roleSelect.value === "Choose a role" ||
  //     (this.hubListSelect.disabled === false && !this.hubListSelect.selectedOptions.length) ||
  //     this.passwordInput.value !== this.repeatPasswordInput.value ||
  //     !emailRegex.test(this.emailInput.value)
  //   ) {
  //     this.createUserError.visible = true;
  //     console.error("Form validation failed.");
  //     return;
  //   }
  //   const selectedHubs = Array.from(this.hubListSelect.selectedOptions).map(option => option.value);
  //   try {
  //     this.eventBus.publish(SHOW_HIDE_SPINNER, { detail: { visible: true } });
  //     const res = await this.authService.register(
  //       this.nameInput.value,
  //       this.emailInput.value,
  //       this.passwordInput.value,
  //       this.roleSelect.value,
  //       selectedHubs
  //     );
  //     if (res?.token) {
  //       Router.go("/dashboard");
  //     }
  //   } catch (error) {
  //     this.createUserError.visible = true;
  //   } finally {
  //     this.eventBus.publish(SHOW_HIDE_SPINNER, { detail: { visible: false } });
  //   }
  // }
  private async handleSubmit(event: Event) {
    event.preventDefault(); // Prevent form from submitting traditionally
    this.createUserError.visible = false;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const selectedHubs = this.selectedHubsTextArea.value.split(',').map(hub => hub.trim()).filter(hub => hub);
    const selectedCategories = this.selectedCategoriesTextArea.value.split(',').map(category => category.trim()).filter(category => category);

    if (
      !this.nameInput.value ||
      !this.emailInput.value ||
      !this.passwordInput.value ||
      !this.repeatPasswordInput.value ||
      this.roleSelect.value === "Choose a role" ||
      (this.hubListSelect.disabled === false && selectedHubs.length === 0) ||
      (this.categoryListSelect.disabled === false && selectedCategories.length === 0) ||
      this.passwordInput.value !== this.repeatPasswordInput.value ||
      !emailRegex.test(this.emailInput.value)
    ) {
      this.createUserError.visible = true;
      console.error("Form validation failed.");
      return;
    }

    try {
      this.eventBus.publish(SHOW_HIDE_SPINNER, { detail: { visible: true } });
      const res = await this.authService.register(
        this.nameInput.value,
        this.emailInput.value,
        this.passwordInput.value,
        this.roleSelect.value,
        selectedHubs,
        selectedCategories
      );
      if (res?.token) {
        Router.go("/dashboard");
      }
    } catch (error) {
      this.createUserError.visible = true;
    } finally {
      this.eventBus.publish(SHOW_HIDE_SPINNER, { detail: { visible: false } });
    }
  }

  private renderOptionsValue(){
    return html`${this.hubList.map(hub => html`<option value="${hub.hubName}">${hub.hubName}</option>`)}`;
  }

  private renderCategoryValue(){
    return html`${this.categoryList.map(category => html`<option value="${category.orderCategory}">${category.orderCategory}</option>`)}`;
  }

  render() {
    return template.call(this);
  }
}

