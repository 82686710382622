import { customElement, query } from "lit/decorators.js";
import template from "./html/dms-login.html";
import { Router } from "@vaadin/router";
import { SERVICE_PROVIDER_ID } from "../../utility/service-provider/service-provider-utility";
import { inject } from "../../utility/decorators/decorators";
import { AuthService } from "../../service/auth/auth-service";
import { SHOW_HIDE_ERROR, SHOW_HIDE_SPINNER } from "../../constants/events";
import { DMSInternalBaseClass } from "../../common/dsm-internal-base-class";

@customElement("dms-login")
export class DeliveryManagementLogin extends DMSInternalBaseClass {
  @query("#emailDMS")
  emailComponent: any;
  @query("#passwordDMS")
  passwordComponent: any;
  @query("#errorElementDMS")
  errorElement: HTMLDivElement;

  @inject(SERVICE_PROVIDER_ID.AUTH_SERVICE)
  private authService: AuthService;

  private async handleClick(e: CustomEvent) {
    e.preventDefault();
    const emailValue = this.emailComponent.value;
    const passwordValue = this.passwordComponent.value;
    try {
      this.eventBus.publish(SHOW_HIDE_ERROR, { detail: { visible: false } });
      this.eventBus.publish(SHOW_HIDE_SPINNER, { detail: { visible: true } });
      this.errorElement?.classList?.add("hidden");
      const res = await this.authService.initiateLogin(
        emailValue,
        passwordValue
      );
      if (res?.token) {
        localStorage.setItem("auth-token-dms", res.token);
        Router.go("/dashboard");
      }
    } catch (error) {
      this.errorElement?.classList?.remove("hidden");
      this.eventBus.publish(SHOW_HIDE_ERROR, {
        detail: {
          visible: true,
          message: "Something wrong with your credentials, Please correct it.",
        },
      });
    } finally {
      this.eventBus.publish(SHOW_HIDE_SPINNER, { detail: { visible: false } });
    }
  }

  constructor() {
    super();
  }

  render() {
    return template.call(this);
  }
}
