import { customElement, property, query } from "lit/decorators.js";
import { DMSInternalBaseClass } from "../../common/dsm-internal-base-class";
import template from "./html/dms-create-hubs.html";
import { SHOW_HIDE_SPINNER } from "../../constants/events";
import { Router } from "@vaadin/router";
import { DMSHubService } from "../../service/hub-service/hubs-service";
import { inject } from "../../utility/decorators/decorators";
import { SERVICE_PROVIDER_ID } from "../../utility/service-provider/service-provider-utility";

@customElement("dms-create-hubs")
export class DeliveryManagementCreateHubs extends DMSInternalBaseClass {
  @inject(SERVICE_PROVIDER_ID.HUBS_SERVICE)
  hubsService: DMSHubService;

  @query(`#hubName`) hubNameInput: HTMLInputElement;
  @query(`#regionName`) regionNameInput: HTMLInputElement;
  @query(`#stateName`) stateNameInput: HTMLInputElement;
  @query(`#cityName`) cityNameInput: HTMLInputElement;
  @query(`#hubTier`) hubTierInput: HTMLInputElement;
  @query(`#createHubs-Error`) createHubsError: any;

  constructor() {
    super();
  }

  private async handleSubmit(event: Event) {
    this.createHubsError.visible = false;
    if (
      !this.hubNameInput.value ||
      !this.regionNameInput.value ||
      !this.stateNameInput.value ||
      !this.cityNameInput.value ||
      !this.hubTierInput.value
    ) {
      this.createHubsError.visible = true;
      console.error("Form validation failed.");
      return;
    }
    try {
      this.eventBus.publish(SHOW_HIDE_SPINNER, { detail: { visible: true } });
      await this.hubsService.create(
        this.hubNameInput.value,
        this.regionNameInput.value,
        this.stateNameInput.value,
        this.cityNameInput.value,
        this.hubTierInput.value
      );

      Router.go("/dashboard");
    } catch (error) {
      this.createHubsError.visible = true;
    } finally {
      this.eventBus.publish(SHOW_HIDE_SPINNER, { detail: { visible: false } });
    }
  }

  render() {
    return template.call(this);
  }
}
