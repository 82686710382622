import { ORDER_CREATE_URL } from "../../constants/api-urls";
import { OrderStatus } from "../../models/order-model";
import { store } from "../../store/dms-store";

export class OrderService {
  async createOrder() {
    const token = localStorage.getItem("auth-token-dms");
    const inputObject = store.getState().Order;
    const response = await fetch(ORDER_CREATE_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(this.constructNewObject(inputObject)),
    });
    if (!response.ok) {
      const errorMessage = `An error occurred: ${response.statusText}`;
      throw new Error(errorMessage);
    }
    const responseData = await response.json();
    return responseData;
  }

  async fetchSingleOrder(orderId: string) {
    const token = localStorage.getItem("auth-token-dms");
    const response = await fetch(`${ORDER_CREATE_URL}/${orderId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      const errorMessage = `An error occurred: ${response.statusText}`;
      throw new Error(errorMessage);
    }
    const responseData = await response.json();
    return responseData;
  }

  async fetchAllOrders() {
    const token = localStorage.getItem("auth-token-dms");
    const response = await fetch(`${ORDER_CREATE_URL}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      const errorMessage = `An error occurred: ${response.statusText}`;
      throw new Error(errorMessage);
    }
    const responseData = await response.json();
    return responseData;
  }

  async updateSingleOrder(
    orderId: string,
    fields: string[],
    orderStatus: OrderStatus
  ) {
    const nonImageFields = fields.filter((field) => !field.includes("Image"));
    const token = localStorage.getItem("auth-token-dms");
    const response = await fetch(`${ORDER_CREATE_URL}/${orderId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(
        this.constructNewUpdateObject(
          store.getState().Order,
          nonImageFields,
          orderStatus
        )
      ),
    });
    if (!response.ok) {
      const errorMessage = `An error occurred: ${response.statusText}`;
      throw new Error(errorMessage);
    }
    const responseData = await response.json();
    return responseData;
  }

  async uploadImage(imageFile: File, orderId: string, prefix: string) {
    const token = localStorage.getItem("auth-token-dms");
    const formData = new FormData();
    formData.append("image", imageFile);
    const response = await fetch(
      `${ORDER_CREATE_URL}/upload-image/${orderId}?prefix=${prefix}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }
    );
    if (!response.ok) {
      const errorMessage = `An error occurred: ${response.statusText}`;
      throw new Error(errorMessage);
    }
    return response;
  }

  private constructNewUpdateObject(
    originalObj: any,
    fieldNames: string[],
    orderStatus: OrderStatus
  ): any {
    const newObj: any = {};
    fieldNames.forEach((field) => {
      if (originalObj.hasOwnProperty(field)) {
        if (
          typeof originalObj[field] === "object" &&
          "value" in originalObj[field]
        ) {
          newObj[field] = originalObj[field].value;
        } else {
          newObj[field] = originalObj[field];
        }
      }
    });
    newObj["orderStatus"] = orderStatus;
    return newObj;
  }

  private constructNewObject(originalObj: any): any {
    const newObj: any = {};
    for (const key in originalObj) {
      if (originalObj.hasOwnProperty(key)) {
        if (
          typeof originalObj[key] === "object" &&
          "value" in originalObj[key]
        ) {
          newObj[key] = originalObj[key].value;
        } else {
          newObj[key] = originalObj[key];
        }
      }
    }
    delete newObj["orderImage"];
    return newObj;
  }
}
