import { DMS_USER_URL, GET_ALL_USER_URL, INIT_DATA } from "../../constants/api-urls";
import { DMSResponseModel } from "../../models/dms-response-model";

export class DMSDataService {
  private dmsData: DMSResponseModel = {} as DMSResponseModel;

  async getInitialData() {
    window.dms.initialData = window.dms.initialData || {};
    const token = localStorage.getItem("auth-token-dms");
    const response = await fetch(INIT_DATA, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    // Check if request was successful
    if (!response.ok) {
      const errorMessage = `An error occurred: ${response.statusText}`;
      throw new Error(errorMessage);
    }

    // Parse response JSON
    const responseData = await response.json();
    window.dms.initialData = responseData;
    this.initializeDmsData();
    return responseData;
  }

  async getAllUsers() {
    const token = localStorage.getItem("auth-token-dms");
    const response = await fetch(GET_ALL_USER_URL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    // Check if request was successful
    if (!response.ok) {
      const errorMessage = `An error occurred: ${response.statusText}`;
      throw new Error(errorMessage);
    }

    // Parse response JSON
    const responseData = await response.json();
    return responseData;
  }

  async fetchSingleUser(userId: string) {
    const token = localStorage.getItem("auth-token-dms");
    const response = await fetch(`${DMS_USER_URL}/${userId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      const errorMessage = `An error occurred: ${response.statusText}`;
      throw new Error(errorMessage);
    }
    const responseData = await response.json();
    return responseData;
  }

  async deleteUser(
    userId: string
  ) {
    const token = localStorage.getItem("auth-token-dms");
    const response = await fetch(`${DMS_USER_URL}/${userId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      const errorMessage = `An error occurred: ${response.statusText}`;
      throw new Error(errorMessage);
    }
    return response;
  }

  async updateSingleUser(
    userId: string,name: string, email: string, role: string, mappedHub: string[], mappedCategories: string[]
  ) {
    const token = localStorage.getItem("auth-token-dms");
    const response = await fetch(`${DMS_USER_URL}/${userId}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        name: name,
        email: email,
        accessLevel: role,
        mappedHub : mappedHub,
        mappedCategories: mappedCategories,
      }),
    });
    if (!response.ok) {
      const errorMessage = `An error occurred: ${response.statusText}`;
      throw new Error(errorMessage);
    }
    const responseData = await response.json();
    return responseData;
  }

  initializeDmsData() {
    const dmsData: DMSResponseModel = window.dms.initialData;
    if (dmsData) {
      this.dmsData.user = dmsData.user;
      this.dmsData.content = dmsData.content;
    }
  }

  public getDMSData() {
    return this.dmsData;
  }
}
