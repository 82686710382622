import { customElement, property } from "lit/decorators.js";
import { DMSInternalBaseClass } from "../../common/dsm-internal-base-class";
import template from "./html/dms-error.html";
import { nothing } from "lit";
import { SHOW_HIDE_ERROR } from "../../constants/events";

@customElement("dms-error")
export class DeliveryManagementError extends DMSInternalBaseClass {
  private visible = false;
  private errorMessage = "";

  private _handleShowHide = this.handleShowHide.bind(this);

  connectedCallback(): void {
    super.connectedCallback();
    this.eventBus.subscribe(SHOW_HIDE_ERROR, this._handleShowHide);
  }

  constructor() {
    super();
  }

  render() {
    return this.visible ? template.call(this) : nothing;
  }

  private handleShowHide(e: CustomEvent) {
    if (e?.detail) {
      this.visible = e?.detail?.visible;
      if (this.visible) this.errorMessage = e?.detail?.message;
      this.requestUpdate();
    }
  }

  disconnectedCallback(): void {
    this.eventBus.unsubscribe(SHOW_HIDE_ERROR, this._handleShowHide);
    super.disconnectedCallback();
  }
}
