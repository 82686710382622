import { customElement, property } from "lit/decorators.js";
import { DMSInternalBaseClass } from "../../common/dsm-internal-base-class";
import template from "./html/dms-select-input.html";
import { store } from "../../store/dms-store";
import { orderActions } from "../../store/order-store";
import { HubDataModel } from "../../models/hub-data-model";

@customElement("dms-select-input")
export class DeliveryManagementSelectInput extends DMSInternalBaseClass {
  @property({ type: Array })
  selectOptions: any;

  private _selectOptions: any;

  @property()
  defaultText: string;

  @property()
  fieldName: string;

  @property({ type: Array })
  hubData: HubDataModel[];

  @property({ type: Boolean })
  required: boolean;

  constructor() {
    super();
  }

  private handleSelect(e: any) {
    if (e.target?.value != this.defaultText) {
      if (this.hubData != undefined) {
        const targetObject = this.hubData.find(
          (obj) => obj.hubName === e.target?.value
        );
        if (targetObject) {
          const { hubName, regionName, stateName, cityName, hubTier } =
            targetObject;
          const newObj = { hubName, regionName, stateName, cityName, hubTier };
          const stateObject = {
            fieldName: this.fieldName,
            fieldValue: newObj,
            isValid: true,
          };
          store.dispatch(orderActions.updateOrder(stateObject));
        }
      } else {
        const stateObject = {
          fieldName: this.fieldName,
          fieldValue: e?.target?.value,
          isValid: true,
        };
        store.dispatch(orderActions.updateOrder(stateObject));
      }
    } else {
      const stateObject = {
        fieldName: this.fieldName,
        fieldValue: "",
        isValid: false,
      };
      store.dispatch(orderActions.updateOrder(stateObject));
    }
  }

  render() {
    this._selectOptions = this.selectOptions.slice().sort((a:any, b:any) => a.toLowerCase().localeCompare(b.toLowerCase()));
    return template.call(this);
  }
}
