import { customElement, property, query } from "lit/decorators.js";
import { DMSInternalBaseClass } from "../../common/dsm-internal-base-class";
import template from "./html/dms-create-categories.html";
import { inject } from "../../utility/decorators/decorators";
import { SERVICE_PROVIDER_ID } from "../../utility/service-provider/service-provider-utility";
import { SHOW_HIDE_SPINNER } from "../../constants/events";
import { Router } from "@vaadin/router";
import { DMSCategoryService } from "../../service/category-service/category-service";

@customElement("dms-create-categories")
export class DeliveryManagementCreateCategories extends DMSInternalBaseClass {
  @inject(SERVICE_PROVIDER_ID.CATEGORY_SERVICE)
  categoryService: DMSCategoryService;

  @query(`#categoryName`) categoryNameInput: HTMLInputElement;
  @query(`#createCategory-Error`) createCategory: any;

  constructor() {
    super();
  }

  private async handleSubmit(event: Event) {
    this.createCategory.visible = false;
    if (!this.categoryNameInput.value) {
      this.createCategory.visible = true;
      console.error("Form validation failed.");
      return;
    }
    try {
      this.eventBus.publish(SHOW_HIDE_SPINNER, { detail: { visible: true } });
      await this.categoryService.create(this.categoryNameInput.value);

      Router.go("/dashboard");
    } catch (error) {
      this.createCategory.visible = true;
    } finally {
      this.eventBus.publish(SHOW_HIDE_SPINNER, { detail: { visible: false } });
    }
  }

  render() {
    return template.call(this);
  }
}
