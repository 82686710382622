import { connect } from "pwa-helpers";
import { AUTH_URL, AUTH_URL_REGISTER } from "../../constants/api-urls";
import { store } from "../../store/dms-store";
import { userActions } from "../../store/user-store";

export class AuthService {
  async initiateLogin(email: string, password: any) {
    const response = await fetch(AUTH_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: email, password: password }),
    });

    // Check if request was successful
    if (!response.ok) {
      const errorMessage = `An error occurred: ${response.statusText}`;
      throw new Error(errorMessage);
    }

    // Parse response JSON
    const responseData = await response.json();
    if (responseData.user) {
      store.dispatch(userActions.upsertUser(responseData.user));
    }
    return responseData;
  }

  async register(name: string, email: string, password: any, role: string, mappedHub: string[], mappedCategories: string[]) {
    const token = localStorage.getItem("auth-token-dms");
    const response = await fetch(AUTH_URL_REGISTER, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        name: name,
        email: email,
        password: password,
        accessLevel: role,
        mappedHub : mappedHub,
        mappedCategories: mappedCategories,
      }),
    });

    // Check if request was successful
    if (!response.ok) {
      const errorMessage = `An error occurred: ${response.statusText}`;
      throw new Error(errorMessage);
    }

    // Parse response JSON
    const responseData = await response.json();
    return responseData;
  }
}
