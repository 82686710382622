import { customElement, property } from "lit/decorators.js";
import { DMSInternalBaseClass } from "../../common/dsm-internal-base-class";
import template from "./html/dms-orders-list-view.html";
import { OrderService } from "../../service/order-service/order-service";
import { inject } from "../../utility/decorators/decorators";
import { SERVICE_PROVIDER_ID } from "../../utility/service-provider/service-provider-utility";
import { PropertyValueMap, html } from "lit";
import { Router } from "@vaadin/router";
import { OrderStatus } from "../../models/order-model";
import { SHOW_HIDE_SPINNER } from "../../constants/events";
import { DMSDataService } from "../../service/dms-data-service/dms-data-service";
import { AccessLevel } from "../../models/user-model";

@customElement("dms-orders-list-view")
export class DeliveryManagementOrdersList extends DMSInternalBaseClass {
  private filters: { [key: string]: string } = {};
  private startDateFilter: Date | null = null;
  private endDateFilter: Date | null = null;

  private ITEMS_PER_PAGE = 10;
  @inject(SERVICE_PROVIDER_ID.ORDER_SERVICE)
  orderService: OrderService;
  @inject(SERVICE_PROVIDER_ID.DMS_DATA_SERVICE)
  private dmsDataService: DMSDataService;

  private currentPage: number = 1;

  private orders: any;
  private userAccess: AccessLevel;

  @property({ type: Boolean }) dataLoaded = false;
  @property() mappedHub: string [];
  @property() mappedCategories: string[];

  constructor() {
    super();
  }

  async connectedCallback() {
    super.connectedCallback();
    try {
      this.eventBus.publish(SHOW_HIDE_SPINNER, { detail: { visible: true } });
      const orderResponse = await this.orderService.fetchAllOrders();
      this.orders = orderResponse.order;
      this.orders = orderResponse.order.sort((a: any, b: any) => {
        // Sort in reverse order based on createdAt timestamp
        return (
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
      });
      
      this.mappedHub =  this.dmsDataService.getDMSData()?.user?.mappedHub;
      this.mappedCategories = this.dmsDataService.getDMSData()?.user?.mappedCategories;
      this.userAccess = this.dmsDataService.getDMSData()?.user?.accessLevel;
      this.dataLoaded = true;
    } catch (error) {
      console.log(error);
    } finally {
      this.eventBus.publish(SHOW_HIDE_SPINNER, {
        detail: { visible: false },
      });
    }
  }

  private handleStartDateChange(event: Event) {
    const input = event.target as HTMLInputElement;
    this.startDateFilter = input.value ? new Date(input.value) : null;
  }

  private handleEndDateChange(event: Event) {
    const input = event.target as HTMLInputElement;
    this.endDateFilter = input.value ? new Date(input.value) : null;
  }

  private handleFilterClick() {
    // Trigger re-rendering by requesting an update
    if (!this.endDateFilter) {
      this.endDateFilter = new Date();
    }
    this.requestUpdate();
  }

  shouldUpdate(): boolean {
    return this.dataLoaded;
  }

  private filteredOrders: any;

  render() {
    this.filteredOrders = this.filterOrders();
    return template.call(this);
  }

  // renderFilterOptions(filterType: string) {
  //   const filterValues = new Set();
  //   this.orders.forEach((order: any) => {
  //     if (filterType === "originHub") {
  //       filterValues.add(order.origin.hubName);
  //     } else if (filterType === "destinationHub") {
  //       filterValues.add(order.destination.hubName);
  //     } else if (filterType === "originStateName") {
  //       filterValues.add(order.origin.stateName);
  //     } else {
  //       filterValues.add(order[filterType]);
  //     }
  //   });
  //   return Array.from(filterValues).map(
  //     (value: any) => html`<option value="${value}">${value}</option>`
  //   );
  // }

  renderFilterOptions(filterType: string) {
    const filterValues = new Set();
    this.orders.forEach((order: any) => {
      if (filterType === "originHub") {
        if (this.mappedHub.length === 0 || this.mappedHub.includes(order.origin.hubName)) {
          filterValues.add(order.origin.hubName);
        }
      } else if (filterType === "destinationHub") {
        if (this.mappedHub.length === 0 || this.mappedHub.includes(order.destination.hubName)) {
          filterValues.add(order.destination.hubName);
        }
      } else if (filterType === "originStateName") {
        filterValues.add(order.origin.stateName);
      } else if (filterType === "orderCategory") {
        if (this.mappedCategories.length === 0 || this.mappedCategories.includes(order.orderCategory)) {
          filterValues.add(order.orderCategory);
        }
      } else {
        filterValues.add(order[filterType]);
      }
    });
    return Array.from(filterValues).map(
      (value: any) => html`<option value="${value}">${value}</option>`
    );
  }

  private handleFilterChange(event: Event, filterType: string) {
    const selectElement = event.target as HTMLSelectElement;
    const filterValue = selectElement.value;
    this.filters[filterType] =
      filterValue === "" ? (undefined as any) : filterValue; // Update the selected dropdown value
    this.requestUpdate(); // Trigger re-render to apply the filters
  }

  private isWithinDateRange(date: Date) {
    if (!this.startDateFilter || !this.endDateFilter) return true;
    const endDateAdjusted = new Date(this.endDateFilter.getTime() + 86400000); // Adding one day in milliseconds
    return date >= this.startDateFilter && date <= endDateAdjusted;
  }

  // filterOrders() {
  //   const { orderStatus, orderCategory, riderName, originHub, destinationHub, originStateName } = this.filters;

  //   return this.orders.filter((order: any) => {
  //     return (!orderStatus || order.orderStatus === orderStatus) &&
  //            (!orderCategory || order.orderCategory === orderCategory) &&
  //            (!riderName || order.riderName === riderName) &&
  //            (!originHub || order.origin?.hubName === originHub) &&
  //            (!destinationHub || order.destination?.hubName === destinationHub) &&
  //            (!originStateName || order.origin?.stateName === originStateName) &&
  //            this.isWithinDateRange(new Date(order.orderCreationTime));
  //   });
  // }

  private searchQuery: string = ""; // Add a property to store the search query

  // Existing methods

  private handleOrderIdSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    this.searchQuery = input.value.trim().toLowerCase();
    this.requestUpdate(); // Trigger re-render to apply the search filter
  }

  // filterOrders() {
  //   const {
  //     orderStatus,
  //     orderCategory,
  //     riderName,
  //     originHub,
  //     destinationHub,
  //     originStateName,
  //   } = this.filters;
  
  //   return this.orders.filter((order: any) => {
  //     const isHubMatched = 
  //       !this.mappedHub?.length || 
  //       this.mappedHub.includes(order.origin?.hubName) || 
  //       this.mappedHub.includes(order.destination?.hubName);
  
  //     const isCategoryMatched = 
  //       !this.mappedCategories?.length || 
  //       this.mappedCategories.includes(order.orderCategory);
  
  //     return (
  //       isHubMatched &&
  //       isCategoryMatched &&
  //       (!orderStatus || order.orderStatus === orderStatus) &&
  //       (!orderCategory || order.orderCategory === orderCategory) &&
  //       (!riderName || order.riderName === riderName) &&
  //       (!originHub || order.origin?.hubName === originHub) &&
  //       (!destinationHub || order.destination?.hubName === destinationHub) &&
  //       (!originStateName || order.origin?.stateName === originStateName) &&
  //       this.isWithinDateRange(new Date(order.orderCreationTime))
  //     );
  //   });
  // }

  filterOrders() {
    const {
      orderStatus,
      orderCategory,
      riderName,
      originHub,
      destinationHub,
      originStateName,
    } = this.filters;

    return this.orders.filter((order: any) => {
      const isHubMatched =
        !this.mappedHub?.length ||
        this.mappedHub.includes(order.origin?.hubName) ||
        this.mappedHub.includes(order.destination?.hubName);

      const isCategoryMatched =
        !this.mappedCategories?.length ||
        this.mappedCategories.includes(order.orderCategory);
      
      const isOrderStatusValid =
      this.userAccess !== AccessLevel.User || order.orderStatus !== OrderStatus.REJECTED;

      return (
        isHubMatched &&
        isCategoryMatched &&
        (!orderStatus || order.orderStatus === orderStatus) &&
        (!orderCategory || order.orderCategory === orderCategory) &&
        (!riderName || order.riderName === riderName) &&
        (!originHub || order.origin?.hubName === originHub) &&
        (!destinationHub || order.destination?.hubName === destinationHub) &&
        (!originStateName || order.origin?.stateName === originStateName) &&
        this.isWithinDateRange(new Date(order.orderCreationTime)) &&
        (this.searchQuery === "" || order.orderId.toLowerCase().includes(this.searchQuery)) &&
        isOrderStatusValid// Include search filter for Order ID
      );
    });
  }
  

  renderHeader() {
    return html`
      <tr>
        <th scope="col" class="px-6 py-3">Order ID</th>
        <th scope="col" class="px-6 py-3">Origin Hub</th>
        <th scope="col" class="px-6 py-3">Destination Hub</th>
        <th scope="col" class="px-6 py-3">Rider Name</th>
        <th scope="col" class="px-6 py-3">Order Status</th>
        <th scope="col" class="px-6 py-3">Order Creation Date</th>
        <th scope="col" class="px-6 py-3">Last Order Status Time</th>
        <th scope="col" class="px-6 py-3">CB RECEIVE STATUS</th>
      </tr>
    `;
  }

  private handleIdClick(order: any) {
    if (order.orderStatus === OrderStatus.CREATED) {
      Router.go(`/order-detail/${order._id}`);
    } else {
      Router.go(`/order-detail/${order._id}/${order.orderStatus}`);
    }
  }

  renderRow(order: any) {
    const dateOptions: Intl.DateTimeFormatOptions = {
      month: "long",
      day: "numeric",
      year: "numeric",
    };
    // Options for formatting the time
    const timeOptions: Intl.DateTimeFormatOptions = {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    };

    let rowClass = ""; // Define a variable to store the row class
    let fontColorClass = ""; // Define a variable to store the font color class
  
    // Assign a different class based on the order status
    switch (order.orderStatus) {
      case OrderStatus.CREATED:
        rowClass = "bg-yellow-200"; // Light yellow
        fontColorClass = "text-yellow-800"; // Dark yellow font color
        break;
      case OrderStatus.DISPATCHED:
        rowClass = "bg-blue-200"; // Light blue
        fontColorClass = "text-blue-800"; // Dark blue font color
        break;
      case OrderStatus.RECEIVED:
        rowClass = "bg-purple-200"; // Light purple
        fontColorClass = "text-purple-800"; // Dark purple font color
        break;
      case OrderStatus.DELIVERED:
        rowClass = "bg-green-200"; // Light green
        fontColorClass = "text-green-800"; // Dark green font color
        break;
      case OrderStatus.REJECTED:
        rowClass = "bg-red-200"; // Light red
        fontColorClass = "text-red-800"; // Dark red font color
        break;
      // Add more cases for other statuses as needed
      default:
        rowClass = ""; // Default class if no status match found
        fontColorClass = ""; // Default font color
    }

    return html`
      <tr class=" ${rowClass} border-b border-emerald-400">
        <td
          scope="row"
          class="px-6 py-4 font-medium whitespace-nowrap ${fontColorClass}"
        >
          <button
            @click=${() => {
              this.handleIdClick(order);
            }}
          >
            ${order.orderId || "---"}
          </button>
        </td>
        <td
          scope="row"
          class="px-6 py-4 font-medium whitespace-nowrap ${fontColorClass}"
        >
          ${order.origin?.hubName || "---"}
        </td>
        <td
          scope="row"
          class="px-6 py-4 font-medium whitespace-nowrap ${fontColorClass}"
        >
          ${order.destination?.hubName || "---"}
        </td>
        <td
          scope="row"
          class="px-6 py-4 font-medium whitespace-nowrap ${fontColorClass}"
        >
          ${order.riderName || "---"}
        </td>
        <td
          scope="row"
          class="px-6 py-4 font-medium whitespace-nowrap ${fontColorClass}"
        >
          ${order.orderStatus || "---"}
        </td>
        <td
          scope="row"
          class="px-6 py-4 font-medium whitespace-nowrap ${fontColorClass}"
        >
          ${new Date(order.orderCreationTime).toLocaleDateString(
            "en-US",
            dateOptions
          ) || "---"}
        </td>
        <td
          scope="row"
          class="px-6 py-4 font-medium whitespace-nowrap ${fontColorClass}"
        >
          ${this.getLastOrderStatusTime(order)}
        </td>
        <td
          scope="row"
          class="px-6 py-4 font-medium whitespace-nowrap ${fontColorClass}"
        >
          ${order.cbStatus || "---"}
        </td>
      </tr>
    `;
  }

  private getLastOrderStatusTime(order: any) {
    switch (order.orderStatus) {
      case OrderStatus.CREATED:
        return order.pickupTime;
      case OrderStatus.DISPATCHED:
        return order.dispatchTime;
      case OrderStatus.DELIVERED:
        return order.deliverTime;
      case OrderStatus.RECEIVED:
        return order.recieveTime;
      case OrderStatus.REJECTED:
        return "---";
      default:
        return "---";
    }
  }

  private navigateToPage(page: number) {
    this.currentPage = page;
    this.requestUpdate();
  }

  renderPagination() {
    const totalPages = Math.ceil(
      this.filteredOrders.length / this.ITEMS_PER_PAGE
    );
    const pages = Array.from({ length: totalPages }, (_, i) => i + 1);
    const isFirstPage = this.currentPage === 1;
    const isLastPage = this.currentPage === totalPages;
    const isEmpty = this.filteredOrders.length === 0;

    const startItem = (this.currentPage - 1) * this.ITEMS_PER_PAGE + 1;
    const endItem = Math.min(
      this.currentPage * this.ITEMS_PER_PAGE,
      this.filteredOrders.length
    );

    // Define how many pages to display in each group
    const pagesPerGroup = 5;
    const currentPageGroup = Math.ceil(this.currentPage / pagesPerGroup);
    const startPage = (currentPageGroup - 1) * pagesPerGroup + 1;
    const endPage = Math.min(startPage + pagesPerGroup - 1, totalPages);

    return html`
      <nav
        class="flex items-center justify-between pt-4"
        aria-label="Table navigation"
      >
        <div class="text-sm font-normal text-gray-500">
          Showing
          <span class="font-semibold">${startItem}</span>
          -
          <span class="font-semibold">${endItem}</span>
          of
          <span class="font-semibold">${this.filteredOrders.length}</span>
        </div>
        <ul class="inline-flex space-x-2">
          <li>
            <a
              href="#"
              class="pagination-link"
              @click=${() => {
                if (!isEmpty && !isFirstPage) this.navigateToPage(1);
              }}
              ?disabled=${isFirstPage}
              >&lt;&lt;</a
            >
          </li>
          <li>
            <a
              href="#"
              class="pagination-link"
              @click=${() => {
                if (!isEmpty && !isFirstPage)
                  this.navigateToPage(this.currentPage - 1);
              }}
              ?disabled=${isFirstPage}
              >Previous</a
            >
          </li>
          ${Array.from(
            { length: endPage - startPage + 1 },
            (_, i) => startPage + i
          ).map(
            (page) => html`
              <li>
                <a
                  href="#"
                  class="pagination-link ${page === this.currentPage
                    ? "active"
                    : ""}"
                  style="${page === this.currentPage
                    ? "background-color: green; color: white;"
                    : ""}"
                  @click=${() => this.navigateToPage(page)}
                  >${page}</a
                >
              </li>
            `
          )}
          <li>
            <a
              href="#"
              class="pagination-link"
              @click=${() => {
                if (!isEmpty && !isLastPage)
                  this.navigateToPage(this.currentPage + 1);
              }}
              ?disabled=${isLastPage}
              >Next</a
            >
          </li>
          <li>
            <a
              href="#"
              class="pagination-link"
              @click=${() => {
                if (!isEmpty && !isLastPage) this.navigateToPage(totalPages);
              }}
              ?disabled=${isLastPage}
              >&gt;&gt;</a
            >
          </li>
        </ul>
      </nav>
    `;
  }
}
