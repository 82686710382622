export interface OrderModel {
  _id:string;
  orderId: string;
  orderCreationTime: string;
  createdByName : string;
  createdByEmail: string;
  origin: hubState;
  destination: hubState;
  orderSize: stringState;
  dispatchMedium: stringState;
  riderName: stringState;
  pickupRemark: stringState;
  orderCategory: stringState;
  orderImage: fileState;
  busName: stringState;
  busNumber: stringState;
  busDriverNumber: stringState;
  eta: stringState;
  orderStatus: OrderStatus;
  busImage: fileState;
  dispatchImage: fileState;
  recievedImage: fileState;
  deliveryImage: fileState;
  localDeliveryRemark: stringState;
  recieveRemark: stringState;
  deliveryRemark: stringState;
  pickupDate: stringState;
  pickupTime: stringState;
  dispatchDate: stringState;
  dispatchTime: stringState;
  recieveTime: stringState;
  recieveDate: stringState;
  deliverTime: stringState;
  deliverDate: stringState;
  deliveryPersonName: stringState;
  remoteRecieverName: stringState;
  contactPerson: stringState;
  contactAddress: stringState;
  contactNumber: stringState;
}

export enum OrderStatus {
  NONE = "NONE",
  CREATED = "CREATED",
  PICKED = "PICKED",
  DISPATCHED = "DISPATCHED",
  RECEIVED = "RECEIVED",
  DELIVERED = "DELIVERED",
  REJECTED = "REJECTED",
}

interface stringState {
  value: string;
  isValid: boolean;
}

interface fileState {
  value: File;
  isValid: boolean;
}

interface hubState {
  value: hubs;
  isValid: boolean;
}

interface hubs {
  hubName: string;
  regionName: string;
  stateName: string;
  cityName: string;
  hubTier: string;
}

export interface OrderResponseModel {
  createdAt: string;
  createdBy: string;
  createdByName: string;
  createdByEmail: string;
  destination: hubs;
  origin: hubs;
  dispatchMedium: string;
  orderCategory: string;
  orderCreationTime: string;
  orderId: string;
  orderSize: string;
  orderStatus: string;
  pickupRemark: string;
  riderName: string;
  localDeliveryRemark: string;
  recieveRemark: string;
  deliveryRemark: string;
  pickupDate: string;
  pickupTime: string;
  dispatchDate: string;
  dispatchTime: string;
  recieveTime: string;
  recieveDate: string;
  deliverTime: string;
  deliverDate: string;
  deliveryPersonName: string;
  remoteRecieverName: string;
  busName: string;
  busNumber: string;
  busDriverNumber: string;
  contactPerson: string;
  contactAddress: string;
  contactNumber: string;
  eta: string;
  _id: string;
}
