import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import { User } from "./user-store";
import { Order } from "./order-store";

const rootReducer = combineReducers({
  User,
  Order,
});

export const store = configureStore({
  reducer: rootReducer,
});

export const state = store.getState();
