import { customElement, property } from "lit/decorators.js";
import { DMSInternalBaseClass } from "../../common/dsm-internal-base-class";
import template from "./html/dms-reports-list-view.html";
import { html } from "lit";
import { OrderService } from "../../service/order-service/order-service";
import { inject } from "../../utility/decorators/decorators";
import { SERVICE_PROVIDER_ID } from "../../utility/service-provider/service-provider-utility";
import * as XLSX from "xlsx";
import { SHOW_HIDE_SPINNER } from "../../constants/events";
import { DMSDataService } from "../../service/dms-data-service/dms-data-service";
import { OrderStatus } from "../../models/order-model";

@customElement("dms-reports-list-view")
export class DeliveryManagementReportsView extends DMSInternalBaseClass {
  private ITEMS_PER_PAGE = 10;
  private currentPage: number = 1;
  private startDate = new Date(2024, 3, 24);

  @inject(SERVICE_PROVIDER_ID.ORDER_SERVICE)
  orderService: OrderService;
  @inject(SERVICE_PROVIDER_ID.DMS_DATA_SERVICE)
  private dmsDataService: DMSDataService;

  private orders: any;

  @property({ type: Boolean }) dataLoaded = false;

  constructor() {
    super();
  }

  async connectedCallback() {
    super.connectedCallback();
    try {
      this.eventBus.publish(SHOW_HIDE_SPINNER, { detail: { visible: true } });
      const orderResponse = await this.orderService.fetchAllOrders();
      this.orders = orderResponse.order;
      this.dataLoaded = true;
    } catch (error) {
      console.log(error);
    } finally {
      this.eventBus.publish(SHOW_HIDE_SPINNER, {
        detail: { visible: false },
      });
    }
  }

  shouldUpdate(): boolean {
    return this.dataLoaded;
  }

  render() {
    return template.call(this);
  }

  renderHeader() {
    return html`
      <tr>
        <th scope="col" class="px-6 py-3">Title</th>
        <th scope="col" class="px-6 py-3 flex justify-center items-center">
          Download Report
        </th>
        <th scope="col" class="px-6 py-3">Time Range</th>
      </tr>
    `;
  }

  private handleIdClick(filenameString: string, startDate: any, endDate: any) {
    const filename = `${filenameString.replace(/\s+/g, "-")}.xlsx`;

    // Get mapped hubs and categories
    const mappedHubs = this.dmsDataService.getDMSData()?.user?.mappedHub;
    const mappedCategories = this.dmsDataService.getDMSData()?.user?.mappedCategories;

    // Filter data based on mapped hubs and categories
    let filteredData = this.orders.slice(); // Copy all orders by default

    if (mappedHubs && mappedHubs.length > 0) {
        filteredData = filteredData.filter((item: any) => {
            return mappedHubs.includes(item.origin.hubName) || mappedHubs.includes(item.destination.hubName);
        });
    }

    if (mappedCategories && mappedCategories.length > 0) {
        filteredData = filteredData.filter((item: any) => {
            return mappedCategories.includes(item.orderCategory);
        });
    }

    filteredData = filteredData.filter((item: any) => item.orderStatus !== OrderStatus.REJECTED);

    // Filter data based on createdAt field within the specified date range
    if (startDate && endDate) {
        const startDateUTC = new Date(startDate);
        startDateUTC.setUTCHours(0, 0, 0, 0);
        const endDateUTC = new Date(endDate);
        endDateUTC.setUTCHours(23, 59, 59, 999);

        filteredData = filteredData.filter((item: any) => {
            const createdAtDate = new Date(item.createdAt);
            return createdAtDate >= startDateUTC && createdAtDate < endDateUTC;
        });
    }

    // Define custom table headings
    const customHeadings = [
        "Serial Number",
        "Pickup Date",
        "Pickup Time",
        "Order Number",
        "Pick Up From",
        "Pick Up Tier",
        "Destination",
        "Destination Tier",
        "Type",
        "Dispatch Medium",
        "Category",
        "Deliver Date",
        "Deliver Time",
        "Origin State"
    ];

    // Map filtered data to the desired format for Excel export
    const data = filteredData.map((item: any, index: number) => ({
        "Serial Number": index + 1,
        "Pickup Date": item.pickupDate,
        "Pickup Time": item.pickupTime,
        "Order Number": item.orderId,
        "Pick Up From": item.origin.hubName,
        "Pick Up Tier": item.origin.hubTier,
        Destination: item.destination.hubName,
        "Destination Tier": item.destination.hubTier,
        Type: item.orderSize,
        "Dispatch Medium": item.dispatchMedium,
        Category: item.orderCategory,
        "Deliver Date": item.deliverDate,
        "Deliver Time": item.deliverTime,
        "Origin State": item.origin?.stateName
    }));

    // Generate Excel sheet
    const ws = XLSX.utils.json_to_sheet(data, { header: customHeadings });
    const columnWidths = customHeadings.map((heading) => ({
        wch: Math.max(
            heading.length,
            ...data.map((row: any) => String(row[heading]).length)
        ),
    }));

    ws["!cols"] = columnWidths;

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Orders");

    // Write workbook to file
    XLSX.writeFile(wb, filename);
}

//   private handleIdClick(filenameString: string, startDate: any, endDate: any) {
//     const filename = `${filenameString.replace(/\s+/g, "-")}.xlsx`;
//     const mappedHubs = this.dmsDataService.getDMSData()?.user?.mappedHub;
    
//     // Check if mappedHubs is provided and it's not empty
//     if (mappedHubs && mappedHubs.length > 0) {
//         // Filter data based on whether origin hub or destination hub matches any of the hubs in mappedHubs
//         const filteredData = this.orders.filter((item: any) => {
//             return mappedHubs.includes(item.origin.hubName) || mappedHubs.includes(item.destination.hubName);
//         });

//         // Generate Excel report with filtered data
//         this.generateExcelReport(filteredData, filename);
//     } else {
//         // If mappedHubs is not provided or empty, generate Excel report with all orders
//         this.generateExcelReport(this.orders, filename);
//     }
// }

// private handleIdClick(filenameString: string, startDate: any, endDate: any) {
//   const filename = `${filenameString.replace(/\s+/g, "-")}.xlsx`;
//   const mappedHubs = this.dmsDataService.getDMSData()?.user?.mappedHub;
//   const mappedCategories = this.dmsDataService.getDMSData()?.user?.mappedCategories;

//   // Check if mappedHubs is provided and it's not empty
//   if (mappedHubs && mappedHubs.length > 0) {
//       // Filter data based on whether origin hub or destination hub matches any of the hubs in mappedHubs
//       const filteredData = this.orders.filter((item: any) => {
//           return mappedHubs.includes(item.origin.hubName) || mappedHubs.includes(item.destination.hubName);
//       });

//       // If mappedCategories is provided and not empty, further filter data based on order categories
//       if (mappedCategories && mappedCategories.length > 0) {
//           return this.generateExcelReport(filteredData.filter((item: any) => {
//               return mappedCategories.includes(item.orderCategory);
//           }), filename);
//       } else {
//           // If mappedCategories is not provided or empty, generate Excel report with filtered data based on mappedHubs only
//           return this.generateExcelReport(filteredData, filename);
//       }
//   } else if (mappedCategories && mappedCategories.length > 0) {
//       // If mappedHubs is not provided or empty, but mappedCategories is, filter data based on order categories only
//       const filteredData = this.orders.filter((item: any) => {
//           return mappedCategories.includes(item.orderCategory);
//       });
      
//       // Generate Excel report with filtered data based on mappedCategories only
//       return this.generateExcelReport(filteredData, filename);
//   } else {
//       // If neither mappedHubs nor mappedCategories is provided or empty, generate Excel report with all orders
//       return this.generateExcelReport(this.orders, filename);
//   }
// }

  private generateExcelReport(data: any[], filename: string) {
    // Define custom table headings
    const customHeadings = [
      "Serial Number",
      "Pickup Date",
      "Pickup Time",
      "Order Number",
      "Pick Up From",
      "Pick Up Tier",
      "Destination",
      "Destination Tier",
      "Type",
      "Dispatch Medium",
      "Category",
      "Deliver Date",
      "Deliver Time",
    ];

    // Map filtered data to the desired format for Excel export
    const formattedData = data.map((item: any, index: number) => ({
      "Serial Number": index + 1,
      "Pickup Date": item.pickupDate,
      "Pickup Time": item.pickupTime,
      "Order Number": item.orderId,
      "Pick Up From": item.origin.hubName,
      "Pick Up Tier": item.origin.hubTier,
      Destination: item.destination.hubName,
      "Destination Tier": item.destination.hubTier,
      Type: item.orderSize,
      "Dispatch Medium": item.dispatchMedium,
      Category: item.orderCategory,
      "Deliver Date": item.deliverDate,
      "Deliver Time": item.deliverTime,
    }));

    // Generate Excel sheet
    const ws = XLSX.utils.json_to_sheet(formattedData, { header: customHeadings });
    const columnWidths = customHeadings.map((heading) => ({
      wch: Math.max(
        heading.length,
        ...formattedData.map((row: any) => String(row[heading]).length)
      ),
    }));

    ws["!cols"] = columnWidths;

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Orders");

    // Write workbook to file
    XLSX.writeFile(wb, filename);
  }

  private startDateFilter: Date | null = null;
  private endDateFilter: Date | null = null;

  private handleStartDateChange(event: Event) {
    const input = event.target as HTMLInputElement;
    this.startDateFilter = input.value ? new Date(input.value) : null;
  }

  private handleEndDateChange(event: Event) {
    const input = event.target as HTMLInputElement;
    this.endDateFilter = input.value ? new Date(input.value) : null;
  }

  private handleFilterClick() {
    // Trigger re-rendering by requesting an update
    if (!this.endDateFilter) {
      this.endDateFilter = new Date();
    }
    this.requestUpdate();
  }

  private isWithinDateRange(date: Date) {
    if (!this.startDateFilter || !this.endDateFilter) return true;
    const endDateAdjusted = new Date(this.endDateFilter.getTime() + 86400000); // Adding one day in milliseconds
    return date >= this.startDateFilter && date <= endDateAdjusted;
  }

  renderRow() {
    const currentDate = new Date();
    const rows = [];
    const currentPage = this.currentPage;
    const ITEMS_PER_PAGE = this.ITEMS_PER_PAGE;

    let currentDateIterator = new Date(currentDate);

    // Generate rows from start date to current date
    while (currentDateIterator >= this.startDate) {
      if (this.isWithinDateRange(currentDateIterator)) {
        const reportRow = this.createReportRow(currentDateIterator);
        rows.push(reportRow);
      }
      // Move to the previous day
      currentDateIterator.setDate(currentDateIterator.getDate() - 1);
    }

    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = currentPage * ITEMS_PER_PAGE;

    return rows.slice(startIndex, endIndex); // Slice the rows array to return only the rows for the current page
  }

  private createReportRow(reportDate: Date) {
    const startDate = new Date(reportDate);
    const endDate = new Date(reportDate);
    startDate.setUTCHours(0, 0, 0, 0); // Set start time to 00:00:00 UTC
    endDate.setUTCHours(23, 59, 59, 999); // Set end time to 23:59:59.999 UTC
    const istStartDate = this.convertToIST(startDate.toISOString());
    const istEndDate = this.convertToIST(endDate.toISOString());

    const reportDateString = `${reportDate.getDate()}th ${reportDate.toLocaleString(
      "en-US",
      { month: "long" }
    )} ${reportDate.getFullYear()}`;

    const startDateString = `${startDate.toLocaleString("en-US", {
      month: "long",
      day: "numeric",
    })}`;
    const endDateString = `${endDate.toLocaleString("en-US", {
      month: "long",
      day: "numeric",
    })}`;
    endDate.setDate(endDate.getDate() + 1);

    const filenamestring = `Report-${reportDateString}`;

    return html`
      <tr class="bg-emerald-500 border-b border-emerald-400">
        <td
          scope="row"
          class="px-6 py-4 font-medium text-emerald-50 whitespace-nowrap dark:text-emerald-100"
        >
          Report-${reportDateString}
        </td>
        <td
          scope="row"
          class="px-6 py-4 font-medium text-emerald-50 whitespace-nowrap dark:text-emerald-100 flex justify-center items-center"
        >
          <button
            @click=${() => {
              this.handleIdClick(filenamestring, istStartDate, istEndDate);
            }}
          >
            <svg
              class="font-medium text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 15v2a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3v-2m-8 1V4m0 12-4-4m4 4 4-4"
              />
            </svg>
          </button>
        </td>
        <td
          scope="row"
          class="px-6 py-4 font-medium text-emerald-50 whitespace-nowrap dark:text-emerald-100"
        >
          ${startDateString} to ${endDateString}
        </td>
      </tr>
    `;
  }

  private convertToIST(utcDateString: string): string {
    const utcDate = new Date(utcDateString);
    const istOffset = 5.5 * 60 * 60 * 1000; // Offset for Indian Standard Time (IST) in milliseconds
    const istDate = new Date(utcDate.getTime() + istOffset);
    return istDate.toISOString(); // Return the date in ISO format
  }

  private navigateToPage(page: number) {
    this.currentPage = page;
    this.requestUpdate();
  }

  renderPagination() {
    const filteredRows = this.renderRow(); // Get the filtered rows based on the date range
    const totalRows = filteredRows.length;
    const totalPages = Math.ceil(totalRows / this.ITEMS_PER_PAGE);
    const pages = Array.from({ length: totalPages }, (_, i) => i + 1);
    const isFirstPage = this.currentPage === 1;
    const isLastPage = this.currentPage === totalPages;
    const isEmpty = totalRows === 0; // Check if riderList is empty

    // Define how many pages to display in each group
    const pagesPerGroup = 5;
    const currentPageGroup = Math.ceil(this.currentPage / pagesPerGroup);
    const startPage = (currentPageGroup - 1) * pagesPerGroup + 1;
    const endPage = Math.min(startPage + pagesPerGroup - 1, totalPages);

    return html`
      <nav
        class="flex items-center flex-column flex-wrap md:flex-row justify-between pt-4"
        aria-label="Table navigation"
      >
        <span
          class="text-sm font-normal text-gray-500 dark:text-gray-400 mb-4 md:mb-0 block w-full md:inline md:w-auto"
        >
          Showing
          <span class="font-semibold text-gray-900 dark:text-white"
            >${totalRows > 0
              ? (this.currentPage - 1) * this.ITEMS_PER_PAGE + 1
              : 0}</span
          >
          -
          <span class="font-semibold text-gray-900 dark:text-white"
            >${totalRows > 0
              ? Math.min(this.currentPage * this.ITEMS_PER_PAGE, totalRows)
              : 0}</span
          >
          of
          <span class="font-semibold text-gray-900 dark:text-white"
            >${totalRows}</span
          >
        </span>
        <ul class="inline-flex -space-x-px rtl:space-x-reverse text-sm h-8">
          <li>
            <a
              href="#"
              class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              @click=${() => {
                if (!isEmpty && !isFirstPage) this.navigateToPage(1);
              }}
              ?disabled=${isEmpty || isFirstPage}
            >
              &lt;&lt;
            </a>
          </li>
          <li>
            <a
              href="#"
              class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              @click=${() => {
                if (!isEmpty && !isFirstPage)
                  this.navigateToPage(this.currentPage - 1);
              }}
              ?disabled=${isEmpty || isFirstPage}
            >
              Previous
            </a>
          </li>
          ${Array.from(
            { length: endPage - startPage + 1 },
            (_, i) => startPage + i
          ).map(
            (page) => html`
              <li>
                <a
                  href="#"
                  class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white ${page ===
                  this.currentPage
                    ? "active"
                    : ""}"
                  style="${page === this.currentPage
                    ? "background-color: green; color: white;"
                    : ""}"
                  @click=${() => this.navigateToPage(page)}
                >
                  ${page}
                </a>
              </li>
            `
          )}
          <li>
            <a
              href="#"
              class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              @click=${() => {
                if (!isEmpty && !isLastPage)
                  this.navigateToPage(this.currentPage + 1);
              }}
              ?disabled=${isEmpty || isLastPage}
            >
              Next
            </a>
          </li>
          <li>
            <a
              href="#"
              class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              @click=${() => {
                if (!isEmpty && !isLastPage) this.navigateToPage(totalPages);
              }}
              ?disabled=${isEmpty || isLastPage}
            >
              &gt;&gt;
            </a>
          </li>
        </ul>
      </nav>
    `;
  }
}
