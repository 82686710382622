import { customElement, property, query } from "lit/decorators.js";
import template from "./html/dms-image-upload.html";
import { store } from "../../store/dms-store";
import { orderActions } from "../../store/order-store";
import { LightElement } from "../../common/light-element";
import { inject } from "../../utility/decorators/decorators";
import { SERVICE_PROVIDER_ID } from "../../utility/service-provider/service-provider-utility";
import { EventBus } from "../../service/event-bus/event-bus";
import { initFlowbite } from "flowbite";
import { SHOW_HIDE_SPINNER } from "../../constants/events";

@customElement("dms-image-upload")
export class DMSImageUpload extends LightElement {
  private videoStream: MediaStream | null = null;
  @property({ type: String }) imageUrl: string | null = null;

  @property({ type: String })
  labelName: string;

  @inject(SERVICE_PROVIDER_ID.EVENT_BUS)
  eventBus: EventBus;

  private imgFile: any;
  @property()
  fieldName: string;

  @query("video")
  private videoElement!: HTMLVideoElement;

  constructor() {
    super();
  }

  firstUpdated(): void {
    initFlowbite();
  }

  private async openCamera() {
    try {
      this.eventBus.publish(SHOW_HIDE_SPINNER, { detail: { visible: true } });
      this.videoStream = await navigator.mediaDevices.getUserMedia({
        video: true,
      });
      this.videoElement.srcObject = this.videoStream;
    } catch (error) {
      console.error("Error accessing camera:", error);
    } finally {
      this.eventBus.publish(SHOW_HIDE_SPINNER, { detail: { visible: false } });
    }
  }

  async takeSnapshot() {
    const canvas = document.createElement("canvas");
    canvas.width = this.videoElement.videoWidth;
    canvas.height = this.videoElement.videoHeight;
    const context = canvas.getContext("2d");
    if (context) {
      context.drawImage(this.videoElement, 0, 0, canvas.width, canvas.height);
      this.imageUrl = canvas.toDataURL("image/jpeg");
      document
        .getElementById(`${this.fieldName}-image-placeholder`)
        ?.classList.remove("hidden");
      document
        .getElementById(`${this.fieldName}-upload-image-place-holder`)
        ?.classList.add("hidden");
      document
        .getElementById(`${this.fieldName}-or-place-holder`)
        ?.classList.add("hidden");
      this.closeCamera();
      const blob = await new Promise<Blob>((resolve) => {
        canvas.toBlob((blob) => {
          if (blob) resolve(blob);
        }, "image/jpeg");
      });

      // Create a File object from the Blob
      this.imgFile = new File([blob], "snapshot.jpg", {
        type: "image/jpeg",
        lastModified: Date.now(),
      });
      const stateObject = {
        fieldName: this.fieldName,
        fieldValue: this.imgFile.lastModified,
        isValid: true,
      };
      store.dispatch(orderActions.updateOrder(stateObject));
      this.eventBus.publish(`${this.fieldName}-file-dispatched`, {
        detail: { file: this.imgFile },
      });
    }
  }

  closeCamera() {
    if (this.videoStream) {
      this.videoStream.getTracks().forEach((track) => track.stop());
    }
  }

  private handleUpload(e: any) {
    if (e.target.files[0].size > 2200000) {
      alert("File is too big!");
      const fileInput = document.getElementById("file_input");
      if (fileInput) {
        (fileInput as any).value = "";
      }
      return;
    }
    const renamedFile = new File([e.target.files[0]], `snapshot.jpg`, { type: e.target.files[0].type });

    this.imgFile = renamedFile;
    document
      .getElementById(`${this.fieldName}-or-place-holder`)
      ?.classList.add("hidden");
    document
      .getElementById(`${this.fieldName}-open-camer-place-holder`)
      ?.classList.add("hidden");
    const stateObject = {
      fieldName: this.fieldName,
      fieldValue: this.imgFile.lastModified,
      isValid: true,
    };
    store.dispatch(orderActions.updateOrder(stateObject));
    this.eventBus.publish(`${this.fieldName}-file-dispatched`, {
      detail: { file: this.imgFile },
    });
  }

  render() {
    return template.call(this);
  }
}
