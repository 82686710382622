import { customElement, property } from "lit/decorators.js";
import { DMSInternalBaseClass } from "../../common/dsm-internal-base-class";
import template from "./html/dms-users-list-view.html";
import { inject } from "../../utility/decorators/decorators";
import { SERVICE_PROVIDER_ID } from "../../utility/service-provider/service-provider-utility";
import { html } from "lit";
import { SHOW_HIDE_SPINNER } from "../../constants/events";
import { DMSDataService } from "../../service/dms-data-service/dms-data-service";
import { Router } from "@vaadin/router";

@customElement("dms-users-list-view")
export class DeliveryManagementCategoryList extends DMSInternalBaseClass {
  private ITEMS_PER_PAGE = 10;
  @inject(SERVICE_PROVIDER_ID.DMS_DATA_SERVICE)
  dmsDataService: DMSDataService;

  private currentPage: number = 1;

  private userList: any;

  @property({ type: Boolean }) dataLoaded = false;

  constructor() {
    super();
  }

  async connectedCallback() {
    super.connectedCallback();
    await this.fetchUsers();
  }

  private async fetchUsers() {
    try {
      this.eventBus.publish(SHOW_HIDE_SPINNER, { detail: { visible: true } });
      const response = await this.dmsDataService.getAllUsers();
      const users = response?.users;
      this.userList = users.sort((a: any, b: any) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
      //this.userList =  users.sort((a: any, b: any) => a.orderCategory.localeCompare(b.orderCategory));
      this.dataLoaded = true;
    } catch (error) {
      console.log(error);
    } finally {
      this.eventBus.publish(SHOW_HIDE_SPINNER, {
        detail: { visible: false },
      });
    }
  }

  shouldUpdate(): boolean {
    return this.dataLoaded;
  }

  render() {
    return template.call(this);
  }

  renderHeader() {
    return html`
      <tr>
        <th scope="col" class="px-6 py-3">Users</th>
        <th scope="col" class="px-6 py-3">Email</th>
        <th scope="col" class="px-6 py-3">Edit</th>
        <th scope="col" class="px-6 py-3">Delete</th>
      </tr>
    `;
  }

  renderRow(userLists: any) {
    return html`
      <tr class="bg-emerald-500 border-b border-emerald-400">
        <td
          scope="row"
          class="px-6 py-4 font-medium text-emerald-50 whitespace-nowrap dark:text-emerald-100"
        >
          ${userLists.name || "---"}
        </td>
        <td
          scope="row"
          class="px-6 py-4 font-medium text-emerald-50 whitespace-nowrap dark:text-emerald-100"
        >
          ${userLists.email || "---"}
        </td>
        <td
          scope="row"
          class="px-6 py-4 font-medium text-emerald-50 whitespace-nowrap dark:text-emerald-100"
        >
          <button
            @click=${() => {
              this.handleEditIcon(userLists);
            }}
          >
            <svg
              class="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                fill-rule="evenodd"
                d="M11.32 6.176H5c-1.105 0-2 .949-2 2.118v10.588C3 20.052 3.895 21 5 21h11c1.105 0 2-.948 2-2.118v-7.75l-3.914 4.144A2.46 2.46 0 0 1 12.81 16l-2.681.568c-1.75.37-3.292-1.263-2.942-3.115l.536-2.839c.097-.512.335-.983.684-1.352l2.914-3.086Z"
                clip-rule="evenodd"
              />
              <path
                fill-rule="evenodd"
                d="M19.846 4.318a2.148 2.148 0 0 0-.437-.692 2.014 2.014 0 0 0-.654-.463 1.92 1.92 0 0 0-1.544 0 2.014 2.014 0 0 0-.654.463l-.546.578 2.852 3.02.546-.579a2.14 2.14 0 0 0 .437-.692 2.244 2.244 0 0 0 0-1.635ZM17.45 8.721 14.597 5.7 9.82 10.76a.54.54 0 0 0-.137.27l-.536 2.84c-.07.37.239.696.588.622l2.682-.567a.492.492 0 0 0 .255-.145l4.778-5.06Z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
        </td>
        <td
          scope="row"
          class="px-6 py-4 font-medium text-emerald-50 whitespace-nowrap dark:text-emerald-100"
        >
          <button
            @click=${() => {
              this.handleDeleteIcon(userLists);
            }}
          >
            <svg
              class="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z"
              />
            </svg>
          </button>
        </td>
      </tr>
    `;
  }

  private handleEditIcon(user: any) {
    Router.go(`/edit-user/${user._id}`);
  }

  async handleDeleteIcon(user: any) {
    try {
      this.eventBus.publish(SHOW_HIDE_SPINNER, { detail: { visible: true } });
      await this.dmsDataService.deleteUser(user?._id);
      await this.fetchUsers();
      const userDetail = this.dmsDataService.getDMSData()?.user;
      if(userDetail._id == user._id){
        localStorage.removeItem("auth-token-dms");
      }
      this.requestUpdate();
    } catch (error) {
      console.log(error);
    } finally {
      this.eventBus.publish(SHOW_HIDE_SPINNER, {
        detail: { visible: false },
      });
    }
  }

  private navigateToPage(page: number) {
    this.currentPage = page;
    this.requestUpdate();
  }

  renderPagination() {
    const totalPages = Math.ceil(this.userList.length / this.ITEMS_PER_PAGE);
    const pages = Array.from({ length: totalPages }, (_, i) => i + 1);
    const isFirstPage = this.currentPage === 1;
    const isLastPage = this.currentPage === totalPages;
    const isEmpty = this.userList.length === 0;

    const startItem = (this.currentPage - 1) * this.ITEMS_PER_PAGE + 1;
    const endItem = Math.min(
      this.currentPage * this.ITEMS_PER_PAGE,
      this.userList.length
    );

    // Define how many pages to display in each group
    const pagesPerGroup = 5;
    const currentPageGroup = Math.ceil(this.currentPage / pagesPerGroup);
    const startPage = (currentPageGroup - 1) * pagesPerGroup + 1;
    const endPage = Math.min(startPage + pagesPerGroup - 1, totalPages);

    return html`
      <nav
        class="flex items-center justify-between pt-4"
        aria-label="Table navigation"
      >
        <div class="text-sm font-normal text-gray-500">
          Showing
          <span class="font-semibold">${startItem}</span>
          -
          <span class="font-semibold">${endItem}</span>
          of
          <span class="font-semibold">${this.userList.length}</span>
        </div>
        <ul class="inline-flex space-x-2">
          <li>
            <a
              href="#"
              class="pagination-link"
              @click=${() => {
                if (!isEmpty && !isFirstPage) this.navigateToPage(1);
              }}
              ?disabled=${isFirstPage}
              >&lt;&lt;</a
            >
          </li>
          <li>
            <a
              href="#"
              class="pagination-link"
              @click=${() => {
                if (!isEmpty && !isFirstPage)
                  this.navigateToPage(this.currentPage - 1);
              }}
              ?disabled=${isFirstPage}
              >Previous</a
            >
          </li>
          ${Array.from(
            { length: endPage - startPage + 1 },
            (_, i) => startPage + i
          ).map(
            (page) => html`
              <li>
                <a
                  href="#"
                  class="pagination-link ${page === this.currentPage
                    ? "active"
                    : ""}"
                  style="${page === this.currentPage
                    ? "background-color: green; color: white;"
                    : ""}"
                  @click=${() => this.navigateToPage(page)}
                  >${page}</a
                >
              </li>
            `
          )}
          <li>
            <a
              href="#"
              class="pagination-link"
              @click=${() => {
                if (!isEmpty && !isLastPage)
                  this.navigateToPage(this.currentPage + 1);
              }}
              ?disabled=${isLastPage}
              >Next</a
            >
          </li>
          <li>
            <a
              href="#"
              class="pagination-link"
              @click=${() => {
                if (!isEmpty && !isLastPage) this.navigateToPage(totalPages);
              }}
              ?disabled=${isLastPage}
              >&gt;&gt;</a
            >
          </li>
        </ul>
      </nav>
    `;
  }
}
