import { customElement } from 'lit/decorators.js';
import template from './html/dms-inaccesible-view.html';
import { initFlowbite } from 'flowbite';
import { DMSInternalBaseClass } from '../../common/dsm-internal-base-class';

@customElement("dms-inaccessible-view")
export class DeliveryManagementInaccessibleView extends DMSInternalBaseClass {
  constructor() {
    super();
    initFlowbite();
  }

  render() {
    return template.call(this);
  }
}
