import { Route, Router } from "@vaadin/router";
import { INIT_DATA } from "../constants/api-urls";
import { DMSDataService } from "../service/dms-data-service/dms-data-service";
import { inject } from "../utility/decorators/decorators";
import { SERVICE_PROVIDER_ID } from "../utility/service-provider/service-provider-utility";
import { EventBus } from "../service/event-bus/event-bus";
import { SHOW_HIDE_SPINNER } from "../constants/events";
import { DeliveryManagementNavBar } from "../components/dms-navbar/dms-navbar";
import { DeliveryManagementSideBar } from "../components/dms-sidebar/dms-sidebar";
import { DeliveryManagementFooter } from "../components/dms-footer/dms-footer";
import { AccessLevel } from "../models/user-model";

export class DMSRouter {
  @inject(SERVICE_PROVIDER_ID.DMS_DATA_SERVICE)
  private dmsDataService: DMSDataService;

  @inject(SERVICE_PROVIDER_ID.EVENT_BUS)
  private eventBus: EventBus;

  private router = new Router();

  public get baseUrl(): string {
    return this.router.baseUrl;
  }

  public get path(): any {
    return this.router.location?.route?.path;
  }

  private hideNavAndSideBar() {
    const navBar = document.getElementById(
      "dmsNavBar"
    ) as DeliveryManagementNavBar;
    const sideBar = document.getElementById(
      "dmsSideBar"
    ) as DeliveryManagementSideBar;
    const footer = document.getElementById(
      "dmsFooter"
    ) as DeliveryManagementFooter;
    navBar.visible = false;
    sideBar.visible = false;
    footer.visible = false;
  }

  private showNavAndSideBar() {
    const navBar = document.getElementById(
      "dmsNavBar"
    ) as DeliveryManagementNavBar;
    const sideBar = document.getElementById(
      "dmsSideBar"
    ) as DeliveryManagementSideBar;
    const footer = document.getElementById(
      "dmsFooter"
    ) as DeliveryManagementFooter;
    navBar.visible = true;
    sideBar.visible = true;
    footer.visible = true;
  }

  private verifyAuth() {
    const token = localStorage.getItem("auth-token-dms");
    if (!token) {
      this.navigate("/");
    }
  }

  private async getInitialData() {
    window.dms.initialData = window.dms.initialData || {};
    const token = localStorage.getItem("auth-token-dms");
    try {
      this.eventBus.publish(SHOW_HIDE_SPINNER, { detail: { visible: true } });
      const response = await fetch(INIT_DATA, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        if (response.status === 401) {
          localStorage.removeItem("auth-token-dms");
          this.navigate("/");
        } else {
          this.navigate("/error");
        }
      }
      const responseData = await response.json();
      window.dms.initialData = responseData;
      this.dmsDataService.initializeDmsData();
    } catch (error) {
      this.navigate("/error");
    } finally {
      this.eventBus.publish(SHOW_HIDE_SPINNER, { detail: { visible: false } });
    }
  }

  initializeRoute = () => {
    const outlet = document.querySelector("#outlet");
    this.router.setOutlet(outlet);
    this.router.setRoutes(this.getRoutes());
  };

  navigate = (route: string) => {
    const url = `${this.router.baseUrl}${route}`;
    window.history.pushState({}, route, url);
    window.dispatchEvent(new PopStateEvent("popstate"));
  };

  // private getRoutes(): Route[] {
  //   return [
  //     {
  //       path: "/",
  //       component: "dms-login",
  //       action: () => {
  //         this.hideNavAndSideBar();
  //         const token = localStorage.getItem("auth-token-dms");
  //         if (token) {
  //           this.navigate("/dashboard");
  //         }
  //       },
  //     },
  //     {
  //       path: "/dashboard",
  //       component: "dms-dashboard",
  //       action: async () => {
  //         this.verifyAuth();
  //         await this.getInitialData();
  //         this.showNavAndSideBar();
  //       },
  //     },
  //     {
  //       path: "/error",
  //       component: "dms-error",
  //       action: () => {
  //         this.hideNavAndSideBar();
  //         this.verifyAuth();
  //       },
  //     },
  //     {
  //       path: "/create-order",
  //       component: "dms-order-create-view",
  //       action: async () => {
  //         this.verifyAuth();
  //         await this.getInitialData();
  //         this.showNavAndSideBar();
  //       },
  //     },
  //     {
  //       path: "/order-detail/:orderId",
  //       component: "dms-order-detail-view",
  //       action: async () => {
  //         this.verifyAuth();
  //         await this.getInitialData();
  //         this.showNavAndSideBar();
  //       },
  //     },
  //     {
  //       path: "/order-detail/:orderId/:status",
  //       component: "dms-order-detail-view",
  //       action: async () => {
  //         this.verifyAuth();
  //         await this.getInitialData();
  //         this.showNavAndSideBar();
  //       },
  //     },
  //     {
  //       path: "/orders",
  //       component: "dms-orders-list-view",
  //       action: async () => {
  //         this.verifyAuth();
  //         await this.getInitialData();
  //         this.showNavAndSideBar();
  //       },
  //     },
  //     {
  //       path: "/users",
  //       component: "dms-users-list-view",
  //       action: async () => {
  //         this.verifyAuth();
  //         await this.getInitialData();
  //         this.showNavAndSideBar();
  //       },
  //     },
  //     {
  //       path: "/hub-list",
  //       component: "dms-hub-list-view",
  //       action: async () => {
  //         this.verifyAuth();
  //         await this.getInitialData();
  //         this.showNavAndSideBar();
  //       },
  //     },
  //     {
  //       path: "/rider-list",
  //       component: "dms-rider-list-view",
  //       action: async () => {
  //         this.verifyAuth();
  //         await this.getInitialData();
  //         this.showNavAndSideBar();
  //       },
  //     },
  //     {
  //       path: "/category-list",
  //       component: "dms-category-list-view",
  //       action: async () => {
  //         this.verifyAuth();
  //         await this.getInitialData();
  //         this.showNavAndSideBar();
  //       },
  //     },
  //     {
  //       path: "/reports",
  //       component: "dms-reports-list-view",
  //       action: async () => {
  //         this.verifyAuth();
  //         await this.getInitialData();
  //         this.showNavAndSideBar();
  //       },
  //     },
  //     {
  //       path: "/create-riders",
  //       component: "dms-create-riders",
  //       action: async () => {
  //         this.verifyAuth();
  //         await this.getInitialData();
  //         this.showNavAndSideBar();
  //       },
  //     },
  //     {
  //       path: "/create-categories",
  //       component: "dms-create-categories",
  //       action: async () => {
  //         this.verifyAuth();
  //         await this.getInitialData();
  //         this.showNavAndSideBar();
  //       },
  //     },
  //     {
  //       path: "/create-users",
  //       component: "dms-create-users",
  //       action: async () => {
  //         this.verifyAuth();
  //         await this.getInitialData();
  //         this.showNavAndSideBar();
  //       },
  //     },
  //     {
  //       path: "/create-hubs",
  //       component: "dms-create-hubs",
  //       action: async () => {
  //         this.verifyAuth();
  //         await this.getInitialData();
  //         this.showNavAndSideBar();
  //       },
  //     },
  //   ];
  // }

  private routeAccessMapping = {
    [AccessLevel.Super_Admin]: [],
    [AccessLevel.Admin]: [
      "/users",
      "/hub-list",
      "/rider-list",
      "/category-list",
      "/create-riders",
      "/create-categories",
      "/create-users",
      "/create-hubs",
    ],
    [AccessLevel.User]: [
      "/users",
      "/hub-list",
      "/rider-list",
      "/category-list",
      "/create-riders",
      "/create-categories",
      "/create-users",
      "/create-hubs",
      "/create-order",
    ],
  };

  private getRoutes(): Route[] {
    const routes: Route[] = [
      {
        path: "/",
        component: "dms-login",
        action: () => {
          this.hideNavAndSideBar();
          const token = localStorage.getItem("auth-token-dms");
          if (token) {
            this.navigate("/dashboard");
          }
        },
      },
      {
        path: "/dashboard",
        component: "dms-dashboard",
        action: async () => {
          await this.getInitialData();
          if (this.checkRouteConditions("/dashboard")) {
            this.verifyAuth();
            this.showNavAndSideBar();
          } else {
            this.navigate("/inaccessible-route");
          }
        },
      },
      {
        path: "/create-order",
        component: "dms-order-create-view",
        action: async () => {
          await this.getInitialData();
          if (this.checkRouteConditions("/create-order")) {
            this.verifyAuth();
            this.showNavAndSideBar();
          } else {
            this.navigate("/inaccessible-route");
          }
        },
      },
      {
        path: "/order-detail/:orderId",
        component: "dms-order-detail-view",
        action: async () => {
          await this.getInitialData();
          if (this.checkRouteConditions("/order-detail/:orderId")) {
            this.verifyAuth();
            this.showNavAndSideBar();
          } else {
            this.navigate("/inaccessible-route");
          }
        },
      },
      {
        path: "/order-detail/:orderId/:status",
        component: "dms-order-detail-view",
        action: async () => {
          await this.getInitialData();
          if (this.checkRouteConditions("/order-detail/:orderId/:status")) {
            this.verifyAuth();
            this.showNavAndSideBar();
          } else {
            this.navigate("/inaccessible-route");
          }
        },
      },
      {
        path: "/edit-user/:userId",
        component: "dms-edit-users",
        action: async () => {
          await this.getInitialData();
          if (this.checkRouteConditions("/edit-user/:userId")) {
            this.verifyAuth();
            this.showNavAndSideBar();
          } else {
            this.navigate("/inaccessible-route");
          }
        },
      },
      {
        path: "/orders",
        component: "dms-orders-list-view",
        action: async () => {
          await this.getInitialData();
          if (this.checkRouteConditions("/orders")) {
            this.verifyAuth();
            this.showNavAndSideBar();
          } else {
            this.navigate("/inaccessible-route");
          }
        },
      },
      {
        path: "/users",
        component: "dms-users-list-view",
        action: async () => {
          await this.getInitialData();
          if (this.checkRouteConditions("/users")) {
            this.verifyAuth();
            this.showNavAndSideBar();
          } else {
            this.navigate("/inaccessible-route");
          }
        },
      },
      {
        path: "/hub-list",
        component: "dms-hub-list-view",
        action: async () => {
          await this.getInitialData();
          if (this.checkRouteConditions("/hub-list")) {
            this.verifyAuth();
            this.showNavAndSideBar();
          } else {
            this.navigate("/inaccessible-route");
          }
        },
      },
      {
        path: "/rider-list",
        component: "dms-rider-list-view",
        action: async () => {
          await this.getInitialData();
          if (this.checkRouteConditions("/rider-list")) {
            this.verifyAuth();
            this.showNavAndSideBar();
          } else {
            this.navigate("/inaccessible-route");
          }
        },
      },
      {
        path: "/category-list",
        component: "dms-category-list-view",
        action: async () => {
          await this.getInitialData();
          if (this.checkRouteConditions("/category-list")) {
            this.verifyAuth();
            this.showNavAndSideBar();
          } else {
            this.navigate("/inaccessible-route");
          }
        },
      },
      {
        path: "/reports",
        component: "dms-reports-list-view",
        action: async () => {
          await this.getInitialData();
          if (this.checkRouteConditions("/reports")) {
            this.verifyAuth();
            this.showNavAndSideBar();
          } else {
            this.navigate("/inaccessible-route");
          }
        },
      },
      {
        path: "/create-riders",
        component: "dms-create-riders",
        action: async () => {
          await this.getInitialData();
          if (this.checkRouteConditions("/create-riders")) {
            this.verifyAuth();
            this.showNavAndSideBar();
          } else {
            this.navigate("/inaccessible-route");
          }
        },
      },
      {
        path: "/create-categories",
        component: "dms-create-categories",
        action: async () => {
          await this.getInitialData();
          if (this.checkRouteConditions("/create-categories")) {
            this.verifyAuth();
            this.showNavAndSideBar();
          } else {
            this.navigate("/inaccessible-route");
          }
        },
      },
      {
        path: "/create-users",
        component: "dms-create-users",
        action: async () => {
          await this.getInitialData();
          if (this.checkRouteConditions("/create-users")) {
            this.verifyAuth();
            this.showNavAndSideBar();
          } else {
            this.navigate("/inaccessible-route");
          }
        },
      },
      {
        path: "/create-hubs",
        component: "dms-create-hubs",
        action: async () => {
          await this.getInitialData();
          if (this.checkRouteConditions("/create-hubs")) {
            this.verifyAuth();
            this.showNavAndSideBar();
          } else {
            this.navigate("/inaccessible-route");
          }
        },
      },
      {
        path: "/inaccessible-route",
        component: "dms-inaccessible-view",
        action: async () => {
          this.verifyAuth();
          await this.getInitialData();
          this.showNavAndSideBar();
        },
      },
    ];

    return routes;
  }

  private checkRouteConditions(route: string): boolean {
    const userDetail = this.dmsDataService.getDMSData()?.user;
    const currentAccessLevel = userDetail?.accessLevel;
    const disallowedRoutes = this.routeAccessMapping[currentAccessLevel];
    if (disallowedRoutes === undefined || disallowedRoutes.length == 0) {
      return true;
    }
    return !(disallowedRoutes as any).includes(route);
  }
}
