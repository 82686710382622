import { customElement, query } from "lit/decorators.js";
import template from "./html/dms-sidebar.html";
import { DMSDataService } from "../../service/dms-data-service/dms-data-service";
import { inject } from "../../utility/decorators/decorators";
import { SERVICE_PROVIDER_ID } from "../../utility/service-provider/service-provider-utility";
import { css, nothing } from "lit";
import { Router } from "@vaadin/router";
import componentStyles from "../../styles/component-styles.scss";
import { LightElement, SharedStylesheet } from "../../common/light-element";
import { AccessLevel } from "../../models/user-model";

@customElement("dms-sidebar")
export class DeliveryManagementSideBar extends LightElement {


  @query('#orderDropNavBar')
  orderNavBar : HTMLElement;
  @query('#userDropNavBar')
  userNavBar : HTMLElement;
  @query('#hubsDropNavBar')
  hubsNavBar : HTMLElement;
  @query('#ridersDropNavBar')
  ridersNavBar : HTMLElement;
  @query('#categoriesDropNavBar')
  categoriesNavBar : HTMLElement;
  @query('#createOrderBtnNavBar')
  createOrderBtnNaveBar : HTMLElement;
  @query('#dashboardNavBar')
  dashboardNavBar : HTMLDivElement;

  static sharedStyles: SharedStylesheet[] = [
    {
      id: "dms-dashboard",
      content: css([`${componentStyles}`] as any),
    },
  ];

  @inject(SERVICE_PROVIDER_ID.DMS_DATA_SERVICE)
  private dmsDataService: DMSDataService;

  private companyName: string;

  private _visible: boolean;
  set visible(value: boolean) {
    this._visible = value;
    this.requestUpdate();
  }
  get visible() {
    return this._visible;
  }

  constructor() {
    super();
  }

 updated(): void {
    if(this.visible){
      this.resolveShowHide();
    }
  }

  private resolveShowHide(){
    const userAccess = this.dmsDataService.getDMSData()?.user?.accessLevel;
    if(userAccess === AccessLevel.Super_Admin){
      this.userNavBar.classList.remove('invisible');
      this.hubsNavBar.classList.remove('invisible');
      this.ridersNavBar.classList.remove('invisible');
      this.categoriesNavBar.classList.remove('invisible');
      this.createOrderBtnNaveBar.classList.remove('invisible');
      this.orderNavBar.classList.remove('invisible');
      this.dashboardNavBar.classList.remove('invisible');
    }
    else if(userAccess === AccessLevel.Admin){
      this.orderNavBar.classList.remove('invisible');
      this.createOrderBtnNaveBar.classList.remove('invisible');
      this.dashboardNavBar.classList.remove('invisible');
    }
    else{
      this.orderNavBar.classList.remove('invisible');
    }
  }

  private createOrder() {
    Router.go("/create-order");
  }

  private goToDashboard() {
    Router.go("/dashboard");
  }

  private orderLists() {
    Router.go("/orders");
  }

  private getReports() {
    Router.go("/reports");
  }

  private createUser() {
    Router.go("/create-users");
  }

  private createHub() {
    Router.go("/create-hubs");
  }

  private createRiders() {
    Router.go("/create-riders");
  }

  private createCategories() {
    Router.go("/create-categories");
  }

  private hubListView(){
    Router.go("/hub-list");
  }

  private usersListView(){
    Router.go("/users");
  }

  private riderListView(){
    Router.go("/rider-list");
  }

  private categoryListView(){
    Router.go("/category-list");
  }
  

  render() {
    if (this.visible) {
      const obj = this.dmsDataService
        .getDMSData()
        ?.content?.find((item) => item?.key === "dms_company_name");
      this.companyName = obj?.value ?? "";
    }
    return this.visible ? template.call(this) : nothing;
  }
}
