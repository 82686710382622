import { CSSResult, LitElement } from "lit";
import { customElement } from "lit/decorators.js";
import { injectSharedStylesheet } from "./style-injector.js";

export interface SharedStylesheet {
  id: string;
  content: CSSResult;
}

@customElement("light-element")
export class LightElement extends LitElement {
  static sharedStyles: SharedStylesheet[] = [];
  elementChildren: Array<ChildNode> = [];
  slotContents: any;

  connectedCallback() {
    const { sharedStyles } = this.constructor as any;
    if (sharedStyles) {
      sharedStyles.forEach((stylesheet: SharedStylesheet) => {
        injectSharedStylesheet(
          this,
          stylesheet.id,
          stylesheet.content.toString()
        );
      });
    }
    this.elementChildren = Array.from(this.childNodes);

    super.connectedCallback();
  }

  get slotElements(): ChildNode[] {
    return this.elementChildren;
  }

  createRenderRoot(): ShadowRoot | this {
    return this;
  }
}
