import { DMSRouter } from "../../router/dms-router";
import { AuthService } from "../../service/auth/auth-service";
import { DMSCategoryService } from "../../service/category-service/category-service";
import { DMSDataService } from "../../service/dms-data-service/dms-data-service";
import { EventBus } from "../../service/event-bus/event-bus";
import { DMSHubService } from "../../service/hub-service/hubs-service";
import { OrderService } from "../../service/order-service/order-service";
import { DMSRidersService } from "../../service/riders-service/riders-service";
import {
  provideService,
  SERVICE_PROVIDER_ID,
} from "./service-provider-utility";

export function initializeServiceProvider() {
  provideService(SERVICE_PROVIDER_ID.DMS_ROUTER, new DMSRouter());
  provideService(SERVICE_PROVIDER_ID.DMS_DATA_SERVICE, new DMSDataService());
  provideService(SERVICE_PROVIDER_ID.AUTH_SERVICE, new AuthService());
  provideService(SERVICE_PROVIDER_ID.EVENT_BUS, new EventBus("dms-event-bus"));
  provideService(SERVICE_PROVIDER_ID.ORDER_SERVICE, new OrderService());
  provideService(SERVICE_PROVIDER_ID.HUBS_SERVICE, new DMSHubService());
  provideService(SERVICE_PROVIDER_ID.RIDERS_SERVICE, new DMSRidersService());
  provideService(SERVICE_PROVIDER_ID.CATEGORY_SERVICE, new DMSCategoryService());
}
