import { customElement, property } from "lit/decorators.js";
import { DMSInternalBaseClass } from "../../common/dsm-internal-base-class";
import template from "./html/dms-section-message.html";
import { nothing } from "lit";

@customElement("dms-section-message")
export class DeliveryManagementSectionMessage extends DMSInternalBaseClass {
  @property({ type: Boolean })
  visible: boolean;

  constructor() {
    super();
  }

  render() {
    return this.visible ? template.call(this) : nothing;
  }
}
