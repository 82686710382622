import { customElement, property } from "lit/decorators.js";
import template from "./html/dms-date-picker.html";
import { LightElement } from "../../common/light-element";
import Datepicker from "flowbite-datepicker/Datepicker";
import { store } from "../../store/dms-store";
import { orderActions } from "../../store/order-store";

@customElement("dms-date-picker")
export class DeliveryManagementDatePicker extends LightElement {
  @property()
  fieldName: string;
  @property({ type: Boolean })
  required: boolean;

  constructor() {
    super();
  }

  private handleChange(e: any) {
    if (e.target?.value) {
      const stateObject = {
        fieldName: this.fieldName,
        fieldValue: e?.target?.value,
        isValid: true,
      };
      store.dispatch(orderActions.updateOrder(stateObject));
    } else if (this.required) {
      const stateObject = {
        fieldName: this.fieldName,
        fieldValue: "",
        isValid: false,
      };
      store.dispatch(orderActions.updateOrder(stateObject));
    } else {
      const stateObject = {
        fieldName: this.fieldName,
        fieldValue: "",
        isValid: true,
      };
      store.dispatch(orderActions.updateOrder(stateObject));
    }
  }

  connectedCallback(): void {
    super.connectedCallback();
  }

  firstUpdated(): void {
    const datepickerEl = document?.getElementById("datepickerId");
    new Datepicker(datepickerEl, {
      autohide: true,
    });
    datepickerEl?.addEventListener("changeDate", (e: any) => {
      this.handleChange(e);
    });
  }

  render() {
    return template.call(this);
  }
}
