import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { User } from "../models/user-model";

const upsertUser = (state: any, action: PayloadAction<User>) => {
  let newState = JSON.parse(JSON.stringify(state));
  if (action.payload != undefined) {
    newState = action.payload;
  }
  return newState;
};

const { actions: userActions, reducer: User } = createSlice({
  name: "User",
  initialState: {},
  reducers: {
    upsertUser: upsertUser,
  },
});

export { userActions, User };
