import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { OrderModel } from "../models/order-model";

const updateOrder = (state: any, action: PayloadAction<any>) => {
  let newState = JSON.parse(JSON.stringify(state));
  if (action.payload != undefined) {
    if (newState[action.payload.fieldName]?.value != undefined) {
      newState[action.payload.fieldName].value = action.payload.fieldValue;
      newState[action.payload.fieldName].isValid = action.payload.isValid;
    } else {
      newState[action.payload.fieldName] = action.payload.fieldValue;
    }
  }
  return newState;
};

const upsertOrder = (state: any, action: PayloadAction<OrderModel>) => {
  let newState = JSON.parse(JSON.stringify(state));
  if (action.payload != undefined) {
    newState = action.payload;
  }
  return newState;
};

const { actions: orderActions, reducer: Order } = createSlice({
  name: "Order",
  initialState: {},
  reducers: {
    updateOrder: updateOrder,
    upsertOrder: upsertOrder,
  },
});

export { orderActions, Order };
