import { customElement } from "lit/decorators.js";
import template from "./html/dms-dashboard.html";
import { initFlowbite } from "flowbite";
import { DMSInternalBaseClass } from "../../common/dsm-internal-base-class";

@customElement("dms-dashboard")
export class DeliveryManagementDashboard extends DMSInternalBaseClass {
  constructor() {
    super();
    initFlowbite();
  }

  render() {
    return template.call(this);
  }
}
