import { customElement, property } from "lit/decorators.js";
import { DMSInternalBaseClass } from "../../common/dsm-internal-base-class";
import template from "./html/dms-spinner.html";
import { nothing } from "lit";
import { SHOW_HIDE_SPINNER } from "../../constants/events";

@customElement("dms-spinner")
export class DeliveryManagementSpinner extends DMSInternalBaseClass {
  private visible = false;

  private _handleShowHide = this.handleShowHide.bind(this);

  connectedCallback(): void {
    super.connectedCallback();
    this.eventBus.subscribe(SHOW_HIDE_SPINNER, this._handleShowHide);
  }

  constructor() {
    super();
  }

  render() {
    return this.visible ? template.call(this) : nothing;
  }

  private handleShowHide(e: CustomEvent) {
    if (e?.detail) {
      this.visible = e?.detail?.visible;
      this.requestUpdate();
    }
  }

  disconnectedCallback(): void {
    this.eventBus.unsubscribe(SHOW_HIDE_SPINNER, this._handleShowHide);
    super.disconnectedCallback();
  }
}
