///for Local host

// export const AUTH_URL = "http://localhost:3000/api/v1/auth/login";
// export const AUTH_URL_REGISTER = "http://localhost:3000/api/v1/auth/register";
// export const INIT_DATA = "http://localhost:3000/api/v1/dms";
// // export const ORDER_CREATE_URL = "http://localhost:3000/api/v1/orders";
// export const ORDER_CREATE_URL = "http://localhost:3000/api/v1/orders/testing";
// export const HUB_FETCH_URL = "http://localhost:3000/api/v1/hubs";
// export const RIDERS_URL = "http://localhost:3000/api/v1/riders";
// export const CATEGORY_URL = "http://localhost:3000/api/v1/category";
// export const BASE_URL = "http://localhost:3000";
// export const GET_ALL_USER_URL = "http://localhost:3000/api/v1/dms/getAllUsers";
// export const DMS_USER_URL = "http://localhost:3000/api/v1/dms/user"

//for live 
export const AUTH_URL = "https://logisticsapi.atreyaglobal.com/api/v1/auth/login";
export const AUTH_URL_REGISTER = "https://logisticsapi.atreyaglobal.com/api/v1/auth/register";
export const INIT_DATA = "https://logisticsapi.atreyaglobal.com/api/v1/dms";
// export const ORDER_CREATE_URL = "https://logisticsapi.atreyaglobal.com/api/v1/orders";
export const ORDER_CREATE_URL = "https://logisticsapi.atreyaglobal.com/api/v1/orders/testing";
export const HUB_FETCH_URL = "https://logisticsapi.atreyaglobal.com/api/v1/hubs";
export const RIDERS_URL = "https://logisticsapi.atreyaglobal.com/api/v1/riders";
export const CATEGORY_URL = "https://logisticsapi.atreyaglobal.com/api/v1/category";
export const BASE_URL = "https://logisticsapi.atreyaglobal.com";
export const GET_ALL_USER_URL = "https://logisticsapi.atreyaglobal.com/api/v1/dms/getAllUsers";
export const DMS_USER_URL = "https://logisticsapi.atreyaglobal.com/api/v1/dms/user"