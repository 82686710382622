import { customElement } from "lit/decorators.js";
import template from "./html/dms-footer.html";
import { nothing } from "lit";
import { LightElement } from "../../common/light-element";

@customElement("dms-footer")
export class DeliveryManagementFooter extends LightElement {
  private _visible: boolean;
  set visible(value: boolean) {
    this._visible = value;
    this.requestUpdate();
  }
  get visible() {
    return this._visible;
  }

  constructor() {
    super();
  }

  render() {
    return this.visible ? template.call(this) : nothing;
  }
}
