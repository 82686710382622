import { LitElement, css } from "lit";
import { EventBus } from "../service/event-bus/event-bus";
import { inject } from "../utility/decorators/decorators";
import { SERVICE_PROVIDER_ID } from "../utility/service-provider/service-provider-utility";
import { customElement } from "lit/decorators.js";
import { initFlowbite } from "flowbite";
import { LightElement, SharedStylesheet } from "./light-element";
import componentStyles from "../styles/component-styles.scss";

@customElement("dms-base")
export class DMSInternalBaseClass extends LightElement {
  @inject(SERVICE_PROVIDER_ID.EVENT_BUS)
  protected eventBus: EventBus;

  static sharedStyles: SharedStylesheet[] = [
    {
      id: "dms-dashboard",
      content: css([`${componentStyles}`] as any),
    },
  ];

  constructor() {
    super();
    initFlowbite();
  }
}
