import { customElement, property } from "lit/decorators.js";
import { DMSInternalBaseClass } from "../../common/dsm-internal-base-class";
import template from "./html/dms-text-input.html";
import { store } from "../../store/dms-store";
import { orderActions } from "../../store/order-store";

@customElement("dms-text-input")
export class DeliveryManagementCard extends DMSInternalBaseClass {
  @property()
  name: string;
  @property()
  fieldName: string;
  @property({ type: Boolean })
  required: boolean;

  constructor() {
    super();
  }

  render() {
    if (!this.required) {
      this.name = `${this.name} (Optional)`;
    }
    return template.call(this);
  }

  private handleChange(e: any) {
    if (e.target?.value) {
      const stateObject = {
        fieldName: this.fieldName,
        fieldValue: e?.target?.value,
        isValid: true,
      };
      store.dispatch(orderActions.updateOrder(stateObject));
    } else if (this.required) {
      const stateObject = {
        fieldName: this.fieldName,
        fieldValue: "",
        isValid: false,
      };
      store.dispatch(orderActions.updateOrder(stateObject));
    } else {
      const stateObject = {
        fieldName: this.fieldName,
        fieldValue: "",
        isValid: true,
      };
      store.dispatch(orderActions.updateOrder(stateObject));
    }
  }
}
