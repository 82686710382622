import { customElement, property } from "lit/decorators.js";
import { DMSInternalBaseClass } from "../../common/dsm-internal-base-class";
import template from "./html/dms-card.html";

@customElement("dms-card")
export class DeliveryManagementCard extends DMSInternalBaseClass {
  @property()
  heading: string;

  @property()
  subHeading: string;

  constructor() {
    super();
  }

  render() {
    return template.call(this);
  }
}
