import { HUB_FETCH_URL } from "../../constants/api-urls";

export class DMSHubService {
  async getHubsData() {
    const token = localStorage.getItem("auth-token-dms");
    const response = await fetch(HUB_FETCH_URL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    // Check if request was successful
    if (!response.ok) {
      const errorMessage = `An error occurred: ${response.statusText}`;
      throw new Error(errorMessage);
    }

    // Parse response JSON
    const responseData = await response.json();
    return responseData;
  }

  async create(
    hubName: string,
    regionName: string,
    stateName: any,
    cityName: string,
    hubTier: string
  ) {
    const token = localStorage.getItem("auth-token-dms");
    const response = await fetch(HUB_FETCH_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify([
        {
          hubName: hubName,
          regionName: regionName,
          stateName: stateName,
          cityName: cityName,
          hubTier: hubTier,
        },
      ]),
    });

    // Check if request was successful
    if (!response.ok) {
      const errorMessage = `An error occurred: ${response.statusText}`;
      throw new Error(errorMessage);
    }

    // Parse response JSON
    const responseData = await response.json();
    return responseData;
  }

  async deleteHub(
    hubId: string
  ) {
    const token = localStorage.getItem("auth-token-dms");
    const response = await fetch(`${HUB_FETCH_URL}/${hubId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      const errorMessage = `An error occurred: ${response.statusText}`;
      throw new Error(errorMessage);
    }
    return response;
  }
}
