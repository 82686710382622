import { customElement, property, query } from "lit/decorators.js";
import { DMSInternalBaseClass } from "../../common/dsm-internal-base-class";
import template from "./html/dms-searchable-select-new.html";
import { HubDataModel } from "../../models/hub-data-model";
import { store } from "../../store/dms-store";
import { orderActions } from "../../store/order-store";

@customElement("dms-searchable-select-new")
export class DeliveryManagementSearchableSelectNew extends DMSInternalBaseClass {

  @query('#wrapperId')
  wrapper: any;
  @query('#optionsID')
  optionsContainer: any;

  @query('#search-bar')
  searchInput: HTMLInputElement;

  @property({ type: Array })
  selectOptions: any;

  private _selectOptions: any;

  @property()
  defaultText: string;

  @property()
  fieldName: string;

  @property({ type: Array })
  hubData: HubDataModel[];

  @property({ type: Boolean })
  required: boolean;

  @property({ type: Array }) filteredCountries : any;


  constructor() {
    super();
  }
  connectedCallback(): void {
    super.connectedCallback();
    this.filteredCountries = [...this.selectOptions];

    // Add event listener to detect clicks outside the dropdown
    window.addEventListener('click', this.handleClickOutside.bind(this));
}

disconnectedCallback(): void {
    super.disconnectedCallback();
    // Remove event listener when the component is disconnected
    window.removeEventListener('click', this.handleClickOutside.bind(this));
}

private handleClickOutside(event: MouseEvent) {
    if (!this.wrapper.contains(event.target) && this.wrapper.classList.contains('active')) {
        this.wrapper.classList.remove('active');
        this.filteredCountries = [...this.selectOptions]; 
        this.searchInput.value = '';
        this.updateOptions();
    }
}

  firstUpdated() {
    this.updateOptions();
  }

  private toggleDropdown() {
    this.wrapper?.classList.toggle('active');
    if (!this.wrapper.classList.contains('active')) {
      this.filteredCountries = [...this.selectOptions]; 
      this.searchInput.value = ''; // Clear search input value
      this.updateOptions();
  }
  }

  private selectOption(e: any) {
    const selectedLi = e.currentTarget as HTMLElement;
    if (e.target?.innerText != this.defaultText) {
      if (this.hubData != undefined) {
        const targetObject = this.hubData.find(
          (obj) => obj.hubName === e.target?.innerText
        );
        if (targetObject) {
          const { hubName, regionName, stateName, cityName, hubTier } =
            targetObject;
          const newObj = { hubName, regionName, stateName, cityName, hubTier };
          const stateObject = {
            fieldName: this.fieldName,
            fieldValue: newObj,
            isValid: true,
          };
          store.dispatch(orderActions.updateOrder(stateObject));
        }
      } else {
        const stateObject = {
          fieldName: this.fieldName,
          fieldValue: e?.target?.innerText,
          isValid: true,
        };
        store.dispatch(orderActions.updateOrder(stateObject));
      }
    } else {
      const stateObject = {
        fieldName: this.fieldName,
        fieldValue: "",
        isValid: false,
      };
      store.dispatch(orderActions.updateOrder(stateObject));
    }
    this.defaultText = selectedLi.innerText;
    this.toggleDropdown();
    this.updateOptions();
  }

  private updateOptions() {
    this.optionsContainer.innerHTML = '';
    this.filteredCountries.forEach((country: any) => {
      const li = document.createElement('li');
      li.innerText = country;
      li.onclick = this.selectOption.bind(this);
      if (country === this.defaultText) {
        li.classList.add('selected');
      }
      this.optionsContainer.appendChild(li);
    });
  }

  private filterOptions(e: Event) {
    const searchInp = e.target as HTMLInputElement;
    const searchWord = searchInp.value.toLowerCase();
    this.filteredCountries = this.selectOptions.filter((country: any) => country.toLowerCase().includes(searchWord));
    this.updateOptions();
  }

  render() {
    this.filteredCountries.slice().sort((a:any, b:any) => a.toLowerCase().localeCompare(b.toLowerCase()));
    return template.call(this);
  }
}

