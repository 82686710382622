import { customElement, property } from "lit/decorators.js";
import { DMSInternalBaseClass } from "../../common/dsm-internal-base-class";
import createTemplate from "./html/dms-order-steps-create.html";
import deliveredTemplate from "./html/dms-order-steps-delivered.html";
import recivedTemplate from "./html/dms-order-steps-recieved.html";
import dispatchedTemplate from "./html/dms-order-steps-dispatch.html";
import rejectTemplate from "./html/dms-order-steps-reject.html";
import { store } from "../../store/dms-store";
import { OrderModel, OrderStatus } from "../../models/order-model";

@customElement("dms-order-steps")
export class DeliveryManagementOrderSteps extends DMSInternalBaseClass {
  private orderStatus: OrderStatus;

  constructor() {
    super();
  }

  connectedCallback(): void {
    super.connectedCallback();
    this.orderStatus = (store.getState().Order as OrderModel).orderStatus;
  }

  render() {
    switch (this.orderStatus) {
      case OrderStatus.CREATED: {
        return createTemplate.call(this);
      }
      case OrderStatus.DISPATCHED: {
        return dispatchedTemplate.call(this);
      }
      case OrderStatus.RECEIVED: {
        return recivedTemplate.call(this);
      }
      case OrderStatus.DELIVERED: {
        return deliveredTemplate.call(this);
      }
      case OrderStatus.REJECTED: {
        return rejectTemplate.call(this);
      }
    }
  }
}
