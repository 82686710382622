import { customElement, property } from "lit/decorators.js";
import { DMSInternalBaseClass } from "../../common/dsm-internal-base-class";
import { OrderStatus, OrderModel } from "../../models/order-model";
import { store } from "../../store/dms-store";
import createTemplate from "./html/dms-recent-actions-create.html";
import pickupTemplate from "./html/dms-recent-actions-pickup.html";
import deliveredTemplate from "./html/dms-recent-actions-delivered.html";
import recivedTemplate from "./html/dms-recent-actions-recieved.html";
import dispatchedTemplate from "./html/dms-recent-actions-dispatch.html";
import rejectTemplate from "./html/dms-recent-actions-reject.html";
import { DMSDataService } from "../../service/dms-data-service/dms-data-service";
import { inject } from "../../utility/decorators/decorators";
import { SERVICE_PROVIDER_ID } from "../../utility/service-provider/service-provider-utility";
import { BASE_URL } from "../../constants/api-urls";
import { BeforeEnterObserver, RouterLocation } from "@vaadin/router";

@customElement("dms-recent-actions")
export class DeliveryManagementRecentActions extends DMSInternalBaseClass{
  @inject(SERVICE_PROVIDER_ID.DMS_DATA_SERVICE)
  private dmsDataService: DMSDataService;

  private orderStatus: OrderStatus;
  private orderMedium: string;
  private userName: string;
  private orderId: string;
  private pickupTime: string;
  private pickupDate: string;
  private dispatchTime: string;
  private dispatchDate: string;
  private recieveTime: string;
  private recieveDate: string;
  private deliverTime: string;
  private deliverDate: string;


  private busImageClass : string;
  private pickupImageUrl: string;
  private dispatchedImageUrl: string;
  private dispatchBusImageUrl: string;
  private recievedImageUrl: string;
  private deliveredImageUrl: string;

  constructor() {
    super();
  }

  connectedCallback(): void {
    super.connectedCallback();
    this.orderStatus = (store.getState().Order as OrderModel).orderStatus;
    this.orderMedium = (store.getState().Order as OrderModel).dispatchMedium.value;
    this.orderId = (store.getState().Order as OrderModel)._id;
    this.userName = (store.getState().Order as OrderModel).createdByName ?? this.dmsDataService.getDMSData()?.user?.name;

    this.pickupImageUrl = `${BASE_URL}/uploads/${this.orderId}/pickup-snapshot.jpg`;
    this.dispatchedImageUrl = `${BASE_URL}/uploads/${this.orderId}/dispatch-snapshot.jpg`;
    this.dispatchBusImageUrl = `${BASE_URL}/uploads/${this.orderId}/bus-snapshot.jpg`;
    this.recievedImageUrl = `${BASE_URL}/uploads/${this.orderId}/recieved-snapshot.jpg`;
    this.deliveredImageUrl = `${BASE_URL}/uploads/${this.orderId}/delivered-snapshot.jpg`;

    this.pickupTime = (store.getState().Order as OrderModel).pickupTime.value;
    this.pickupDate = (store.getState().Order as OrderModel).pickupDate.value;
    this.dispatchTime = (store.getState().Order as OrderModel).dispatchTime.value;
    this.dispatchDate = (store.getState().Order as OrderModel).dispatchDate.value;
    this.deliverTime = (store.getState().Order as OrderModel).deliverTime.value;
    this.deliverDate = (store.getState().Order as OrderModel).deliverDate.value;
    this.recieveTime = (store.getState().Order as OrderModel).recieveTime.value;
    this.recieveDate = (store.getState().Order as OrderModel).recieveDate.value;
  }

  render() {
    this.busImageClass= this.orderMedium === 'Bus'? 'visible' : 'invisible';
    switch (this.orderStatus) {
      case OrderStatus.CREATED: {
        return createTemplate.call(this);
      }
      case OrderStatus.PICKED: {
        return pickupTemplate.call(this);
      }
      case OrderStatus.DISPATCHED: {
        return dispatchedTemplate.call(this);
      }
      case OrderStatus.RECEIVED: {
        return recivedTemplate.call(this);
      }
      case OrderStatus.DELIVERED: {
        return deliveredTemplate.call(this);
      }
      case OrderStatus.REJECTED: {
        return rejectTemplate.call(this);
      }
    }
  }
}
