import { customElement, property } from "lit/decorators.js";
import template from "./html/dms-image-viewer.html";
import { LightElement } from "../../common/light-element";
import { inject } from "../../utility/decorators/decorators";
import { SERVICE_PROVIDER_ID } from "../../utility/service-provider/service-provider-utility";
import { EventBus } from "../../service/event-bus/event-bus";
import { initFlowbite } from "flowbite";

@customElement("dms-image-viewer")
export class DMSImageViewer extends LightElement{
    
  @inject(SERVICE_PROVIDER_ID.EVENT_BUS)
  eventBus: EventBus;

  @property({ type: String })
  labelValue: string;
  @property()
  fieldName: string;
  @property()
  heading: string;
  @property()
  imageUrl: string;

  constructor() {
    super();
  }

  firstUpdated(): void {
    initFlowbite();
  }

  render() {
    return template.call(this);
  }

  private viewImage(e: any){
    e.preventDefault();
  }
}
