export interface User {
  _id: string;
  name: string;
  email: string;
  avtarUrl: string;
  accessLevel: AccessLevel;
  mappedHub : string[];
  mappedCategories : string[];
}

export enum AccessLevel {
  Super_Admin = "Super_Admin",
  Admin = "Admin",
  User = "User",
}
