import { customElement, property } from "lit/decorators.js";
import template from "./html/dms-order-detail-view.html";
import { BeforeEnterObserver, Router, RouterLocation } from "@vaadin/router";
import { inject } from "../../utility/decorators/decorators";
import { SERVICE_PROVIDER_ID } from "../../utility/service-provider/service-provider-utility";
import { OrderService } from "../../service/order-service/order-service";
import { LightElement } from "../../common/light-element";
import { initFlowbite } from "flowbite";
import { store } from "../../store/dms-store";
import { orderActions } from "../../store/order-store";
import { OrderModel, OrderResponseModel } from "../../models/order-model";
import { EventBus } from "../../service/event-bus/event-bus";
import { SHOW_HIDE_SPINNER } from "../../constants/events";

@customElement("dms-order-detail-view")
export class DeliveryManagementOrderDetailView
  extends LightElement
  implements BeforeEnterObserver
{
  @inject(SERVICE_PROVIDER_ID.ORDER_SERVICE)
  orderService: OrderService;

  @inject(SERVICE_PROVIDER_ID.EVENT_BUS)
  protected eventBus: EventBus;

  @property({ type: Boolean })
  dataLoaded = false;
  @property()
  orderId: string;

  @property()
  orderStatus: string;

  @property()
  orderDetails: any;

  constructor() {
    super();
  }

  async connectedCallback(): Promise<void> {
    super.connectedCallback();
    try {
      this.eventBus.publish(SHOW_HIDE_SPINNER, { detail: { visible: true } });
      this.orderDetails = await this.orderService.fetchSingleOrder(
        this.orderId
      );
      const orderObject: OrderResponseModel = this.orderDetails.order;
      this.dataLoaded = true;
      if (this.orderDetails.order) {
        const initOrderObject = {
          _id: orderObject._id,
          orderId: orderObject.orderId,
          orderCreationTime: orderObject.orderCreationTime,
          createdByName : orderObject.createdByName,
          createdByEmail: orderObject.createdByEmail,
          orderStatus: orderObject.orderStatus,
          origin: {
            value: orderObject.origin,
            isValid: true,
          },
          destination: {
            value: orderObject.destination,
            isValid: true,
          },
          orderSize: {
            value: orderObject.orderSize,
            isValid: true,
          },
          dispatchMedium: {
            value: orderObject.dispatchMedium,
            isValid: true,
          },
          riderName: {
            value: orderObject.riderName,
            isValid: true,
          },
          pickupRemark: {
            value: orderObject.pickupRemark,
            isValid: true,
          },
          orderCategory: {
            value: orderObject.orderCategory,
            isValid: true,
          },
          pickupDate: {
            value: orderObject.pickupDate,
            isValid: true,
          },
          pickupTime: {
            value: orderObject.pickupTime,
            isValid: true,
          },
          dispatchDate: {
            value: orderObject.dispatchDate ?? "",
            isValid: false,
          },
          dispatchTime: {
            value: orderObject.dispatchTime ?? "",
            isValid: false,
          },
          deliverDate: {
            value: orderObject.deliverDate ??"",
            isValid: false,
          },
          deliverTime: {
            value: orderObject.deliverTime ?? "",
            isValid: false,
          },
          recieveDate: {
            value: orderObject.recieveDate ?? "",
            isValid: false,
          },
          recieveTime: {
            value: orderObject.recieveTime ?? "",
            isValid: false,
          },
          busName: {
            value: orderObject.busName ?? "",
            isValid: false,
          },
          busNumber: {
            value: orderObject.busNumber ?? "",
            isValid: false,
          },
          busDriverNumber: {
            value: orderObject.busDriverNumber ?? "",
            isValid: false,
          },
          eta: {
            value: orderObject.eta ?? "",
            isValid: false,
          },
          busImage: {
            value: "dummy" as unknown as File,
            isValid: false,
          },
          dispatchImage: {
            value: "dummy" as unknown as File,
            isValid: false,
          },
          recievedImage: {
            value: "dummy" as unknown as File,
            isValid: false,
          },
          deliveryImage: {
            value: "dummy" as unknown as File,
            isValid: false,
          },
          localDeliveryRemark: {
            value: orderObject.localDeliveryRemark ?? "",
            isValid: true,
          },
          recieveRemark: {
            value: orderObject.recieveRemark ?? "",
            isValid: true,
          },
          deliveryRemark: {
            value: orderObject.deliveryRemark ?? "",
            isValid: true,
          },
          deliveryPersonName: {
            value: orderObject.deliveryPersonName ?? "",
            isValid: false,
          },
          remoteRecieverName: {
            value: orderObject.remoteRecieverName ?? "",
            isValid: false,
          },
          contactPerson: {
            value: orderObject.contactPerson ?? "",
            isValid: false,
          }, 
          contactAddress: {
            value: orderObject.contactAddress ?? "",
            isValid: false,
          }, 
          contactNumber: {
            value: orderObject.contactNumber ?? "",
            isValid: false,
          }, 
        } as OrderModel;
        store.dispatch(orderActions.upsertOrder(initOrderObject));
      }
    } catch (error) {
      this.dataLoaded = false;
    } finally {
      this.eventBus.publish(SHOW_HIDE_SPINNER, {
        detail: { visible: false },
      });
    }
  }

  async onBeforeEnter(location: RouterLocation) {
    const orderID = location.params.orderId as string;
    this.orderId = orderID;
    const orderStatus = location.params.status as string;
    this.orderStatus = orderStatus;
  }

  async onAfterEnter(location: RouterLocation) {
    this.requestUpdate();
  }

  private ordersList() {
    Router.go("/orders");
  }

  firstUpdated(): void {
    initFlowbite();
  }

  shouldUpdate(): boolean {
    return this.dataLoaded;
  }

  render() {
    return template.call(this);
  }
}
