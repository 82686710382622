import { CATEGORY_URL } from "../../constants/api-urls";

export class DMSCategoryService {
  async getCategoryData() {
    const token = localStorage.getItem("auth-token-dms");
    const response = await fetch(CATEGORY_URL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    // Check if request was successful
    if (!response.ok) {
      const errorMessage = `An error occurred: ${response.statusText}`;
      throw new Error(errorMessage);
    }

    // Parse response JSON
    const responseData = await response.json();
    return responseData;
  }

  async create(orderCategory: string) {
    const token = localStorage.getItem("auth-token-dms");
    const response = await fetch(CATEGORY_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify([{ orderCategory: orderCategory }]),
    });

    // Check if request was successful
    if (!response.ok) {
      const errorMessage = `An error occurred: ${response.statusText}`;
      throw new Error(errorMessage);
    }

    // Parse response JSON
    const responseData = await response.json();
    return responseData;
  }

  async deleteCategory(
    categoryId: string
  ) {
    const token = localStorage.getItem("auth-token-dms");
    const response = await fetch(`${CATEGORY_URL}/${categoryId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      const errorMessage = `An error occurred: ${response.statusText}`;
      throw new Error(errorMessage);
    }
    return response;
  }
}
