import { customElement, property } from "lit/decorators.js";
import { DMSInternalBaseClass } from "../../common/dsm-internal-base-class";
import template from "./html/dms-time-picker.html";
import { store } from "../../store/dms-store";
import { orderActions } from "../../store/order-store";

@customElement("dms-time-picker")
export class DeliveryManagementTimePicker extends DMSInternalBaseClass {
  @property()
  fieldName: string;
  @property({ type: Boolean })
  required: boolean;

  @property({ type: Number }) selectedHour: number = 1;
  @property({ type: Number }) selectedMinute: number = 0;
  @property({ type: String }) selectedAMPM: string = "AM";

  handleHourChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    this.selectedHour = parseInt(selectElement.value);
    this.updateTime();
  }

  handleMinuteChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    this.selectedMinute = parseInt(selectElement.value);
    this.updateTime();
  }

  handleAMPMChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    this.selectedAMPM = selectElement.value;
    this.updateTime();
  }

  firstUpdated() {
    this.updateTime();
  }

  updateTime() {
    const formattedHour =
      this.selectedHour < 10 ? `0${this.selectedHour}` : this.selectedHour;
    const formattedMinute =
      this.selectedMinute < 10
        ? `0${this.selectedMinute}`
        : this.selectedMinute;
    const finalTime = `${formattedHour}:${formattedMinute}:${this.selectedAMPM}`;
    const stateObject = {
      fieldName: this.fieldName,
      fieldValue: finalTime,
      isValid: true,
    };
    store.dispatch(orderActions.updateOrder(stateObject));
  }

  constructor() {
    super();
  }

  render() {
    return template.call(this);
  }
}
