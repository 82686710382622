import { customElement } from "lit/decorators.js";
import { DMSInternalBaseClass } from "../../common/dsm-internal-base-class";
import template from "./html/dms-order-basic-detail.html";
import { store } from "../../store/dms-store";
import { DMSDataService } from "../../service/dms-data-service/dms-data-service";
import { inject } from "../../utility/decorators/decorators";
import { SERVICE_PROVIDER_ID } from "../../utility/service-provider/service-provider-utility";
import { User } from "../../models/user-model";
import { OrderModel } from "../../models/order-model";

@customElement("dms-order-basic-detail")
export class DeliveryManagementOrderBasicDetail extends DMSInternalBaseClass {
  @inject(SERVICE_PROVIDER_ID.DMS_DATA_SERVICE)
  private dmsDataService: DMSDataService;

  private userDetail: User;
  private orderDetail: any;
  private createdUserName: any
  private createdUserEmail: any

  constructor() {
    super();
    this.createdUserName = (store.getState().Order as OrderModel).createdByName ?? this.dmsDataService.getDMSData()?.user?.name;
    this.createdUserEmail = (store.getState().Order as OrderModel).createdByEmail ?? this.dmsDataService.getDMSData()?.user?.email;
    this.userDetail = this.dmsDataService.getDMSData()?.user;
    this.orderDetail = store.getState().Order;
  }

  render() {
    return template.call(this);
  }
}
