import { customElement, property } from "lit/decorators.js";
import { DMSInternalBaseClass } from "../../common/dsm-internal-base-class";
import template from "./html/dms-rider-list-view.html";
import { inject } from "../../utility/decorators/decorators";
import { SERVICE_PROVIDER_ID } from "../../utility/service-provider/service-provider-utility";
import { html } from "lit";
import { SHOW_HIDE_SPINNER } from "../../constants/events";
import { DMSRidersService } from "../../service/riders-service/riders-service";

@customElement("dms-rider-list-view")
export class DeliveryManagementRidersList extends DMSInternalBaseClass {
  private ITEMS_PER_PAGE = 10;
  @inject(SERVICE_PROVIDER_ID.RIDERS_SERVICE)
  riderService: DMSRidersService;

  private currentPage: number = 1;

  private riderList: any;

  @property({ type: Boolean }) dataLoaded = false;

  constructor() {
    super();
  }

  async connectedCallback() {
    super.connectedCallback();
    await this.fetchRiders();
  }

  private async fetchRiders() {
    try {
      this.eventBus.publish(SHOW_HIDE_SPINNER, { detail: { visible: true } });
      const response = await this.riderService.getRidersData();
      const riderData = response?.riders;
      this.riderList = riderData.sort((a: any, b: any) =>
        a.riderName.localeCompare(b.riderName)
      );
      this.dataLoaded = true;
    } catch (error) {
      console.log(error);
    } finally {
      this.eventBus.publish(SHOW_HIDE_SPINNER, {
        detail: { visible: false },
      });
    }
  }

  shouldUpdate(): boolean {
    return this.dataLoaded;
  }

  render() {
    return template.call(this);
  }

  renderHeader() {
    return html`
      <tr>
        <th scope="col" class="px-6 py-3">Rider Name</th>
        <th scope="col" class="px-6 py-3">Action</th>
      </tr>
    `;
  }

  async handleDeleteIcon(rider: any) {
    try {
      this.eventBus.publish(SHOW_HIDE_SPINNER, { detail: { visible: true } });
      await this.riderService.deleteRider(rider?._id);
      await this.fetchRiders();
      this.requestUpdate();
    } catch (error) {
      console.log(error);
    } finally {
      this.eventBus.publish(SHOW_HIDE_SPINNER, {
        detail: { visible: false },
      });
    }
  }

  renderRow(rider: any) {
    return html`
      <tr class="bg-emerald-500 border-b border-emerald-400">
        <td
          scope="row"
          class="px-6 py-4 font-medium text-emerald-50 whitespace-nowrap dark:text-emerald-100"
        >
          ${rider?.riderName || "---"}
        </td>
        <td
          scope="row"
          class="px-6 py-4 font-medium text-emerald-50 whitespace-nowrap dark:text-emerald-100"
        >
          <button
            @click=${() => {
              this.handleDeleteIcon(rider);
            }}
          >
            <svg
              class="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z"
              />
            </svg>
          </button>
        </td>
      </tr>
    `;
  }

  private navigateToPage(page: number) {
    this.currentPage = page;
    this.requestUpdate();
  }

  renderPagination() {
    const totalPages = Math.ceil(this.riderList.length / this.ITEMS_PER_PAGE);
    const pages = Array.from({ length: totalPages }, (_, i) => i + 1);
    const isFirstPage = this.currentPage === 1;
    const isLastPage = this.currentPage === totalPages;
    const isEmpty = this.riderList.length === 0;
  
    const startItem = (this.currentPage - 1) * this.ITEMS_PER_PAGE + 1;
    const endItem = Math.min(this.currentPage * this.ITEMS_PER_PAGE, this.riderList.length);
  
    // Define how many pages to display in each group
    const pagesPerGroup = 5;
    const currentPageGroup = Math.ceil(this.currentPage / pagesPerGroup);
    const startPage = (currentPageGroup - 1) * pagesPerGroup + 1;
    const endPage = Math.min(startPage + pagesPerGroup - 1, totalPages);
  
    return html`
      <nav class="flex items-center justify-between pt-4" aria-label="Table navigation">
        <div class="text-sm font-normal text-gray-500">
          Showing
          <span class="font-semibold">${startItem}</span>
          -
          <span class="font-semibold">${endItem}</span>
          of
          <span class="font-semibold">${this.riderList.length}</span>
        </div>
        <ul class="inline-flex space-x-2">
          <li>
            <a href="#" class="pagination-link" @click=${() => {
              if (!isEmpty && !isFirstPage) this.navigateToPage(1);
            }} ?disabled=${isFirstPage}>&lt;&lt;</a>
          </li>
          <li>
            <a href="#" class="pagination-link" @click=${() => {
              if (!isEmpty && !isFirstPage) this.navigateToPage(this.currentPage - 1);
            }} ?disabled=${isFirstPage}>Previous</a>
          </li>
          ${Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i).map(page => html`
            <li>
              <a href="#" class="pagination-link ${page === this.currentPage ? 'active' : ''}" style="${page === this.currentPage ? 'background-color: green; color: white;' : ''}" @click=${() => this.navigateToPage(page)}>${page}</a>
            </li>
          `)}
          <li>
            <a href="#" class="pagination-link" @click=${() => {
              if (!isEmpty && !isLastPage) this.navigateToPage(this.currentPage + 1);
            }} ?disabled=${isLastPage}>Next</a>
          </li>
          <li>
            <a href="#" class="pagination-link" @click=${() => {
              if (!isEmpty && !isLastPage) this.navigateToPage(totalPages);
            }} ?disabled=${isLastPage}>&gt;&gt;</a>
          </li>
        </ul>
      </nav>
    `;
  }
  
}
