import { customElement } from "lit/decorators.js";
import template from "./html/dms-navbar.html";
import { AccessLevel, User } from "../../models/user-model";
import { DMSRouter } from "../../router/dms-router";
import { inject } from "../../utility/decorators/decorators";
import { SERVICE_PROVIDER_ID } from "../../utility/service-provider/service-provider-utility";
import { DMSDataService } from "../../service/dms-data-service/dms-data-service";
import { nothing } from "lit";
import { Router } from "@vaadin/router";
import { LightElement } from "../../common/light-element";

@customElement("dms-navbar")
export class DeliveryManagementNavBar extends LightElement {
  @inject(SERVICE_PROVIDER_ID.DMS_ROUTER)
  private dmsRouter: DMSRouter;
  @inject(SERVICE_PROVIDER_ID.DMS_DATA_SERVICE)
  private dmsDataService: DMSDataService;

  private _visible: boolean;
  set visible(value: boolean) {
    this._visible = value;
    this.requestUpdate();
  }
  get visible() {
    return this._visible;
  }

  private userDetail: User;
  private accessLevel: string;

  constructor() {
    super();
  }

  render() {
    if (this.visible) {
      this.userDetail = this.dmsDataService.getDMSData()?.user;
      const accessLevelMapping = {
        [AccessLevel.Super_Admin] : 'Super Admin',
        [AccessLevel.Admin] : 'Admin',
        [AccessLevel.User]: 'User',
      }
      const retrievedAccessLevel =  this.userDetail?.accessLevel;
      this.accessLevel =  accessLevelMapping[retrievedAccessLevel];
      if (!this.userDetail?.avtarUrl) {
        if(this.userDetail){
          this.userDetail.avtarUrl =
          "https://avatar.iran.liara.run/public/boy?username=Ash";
        }
      }
    }
    return this.visible ? template.call(this) : nothing;
  }

  private logout() {
    try {
      localStorage.removeItem("auth-token-dms");
      this.dmsRouter.navigate("/");
    } catch (e) {
      console.log("No such token present");
    }
  }

  private goToDashboard() {
    Router.go("/dashboard");
  }
}
