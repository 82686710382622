import { customElement, property, query } from "lit/decorators.js";
import template from "./html/dms-table-view.html";
import { Router } from "@vaadin/router";
import { DMSInternalBaseClass } from "../../common/dsm-internal-base-class";
import Chart from "chart.js/auto";
import { css, html } from "lit";
import { OrderService } from "../../service/order-service/order-service";
import { inject } from "../../utility/decorators/decorators";
import { SERVICE_PROVIDER_ID } from "../../utility/service-provider/service-provider-utility";
import { OrderModel, OrderResponseModel } from "../../models/order-model";
import "chartjs-adapter-date-fns";
import { SHOW_HIDE_SPINNER } from "../../constants/events";
import { DMSDataService } from "../../service/dms-data-service/dms-data-service";
import { AccessLevel } from "../../models/user-model";
import manageButtonTemplate from './html/dms-manage-button-view.html';

@customElement("dms-table-view")
export class DeliveryManagementTableView extends DMSInternalBaseClass {
  @property({ type: Array }) orders: any[] = [];
  @inject(SERVICE_PROVIDER_ID.ORDER_SERVICE)
  orderService: OrderService;
  @inject(SERVICE_PROVIDER_ID.DMS_DATA_SERVICE)
  private dmsDataService: DMSDataService;


  private handleCreateOrder() {
    Router.go("/create-order");
  }

  private handleOrderManagement() {
    Router.go("/orders");
  }

  async connectedCallback() {
    super.connectedCallback();
    try {
      this.eventBus.publish(SHOW_HIDE_SPINNER, { detail: { visible: true } });
      const orderResponse = await this.orderService.fetchAllOrders();
      this.orders = orderResponse.order;
      this.createDonughtChartForCategories();
      this.createPieChartForSizes();
      this.createBarChartForDispatchMedium();
      this.createMultiLineChartForOrderStatus();
    } catch (error) {
      console.log(error);
    } finally {
      this.eventBus.publish(SHOW_HIDE_SPINNER, {
        detail: { visible: false },
      });
    }
  }

  private createDonughtChartForCategories() {
    const orderCategoriesCount: { [category: string]: number } = {};
    this.orders.forEach((order) => {
      const category = order.orderCategory;
      orderCategoriesCount[category] =
        (orderCategoriesCount[category] || 0) + 1;
    });
    // const categoryColors: { [category: string]: string } = {
    //   "Pharmacy Order": "#FF6384",
    //   "Sample Order": "#36A2EB",
    //   "B2B Sample": "#FFCE56",
    //   "Consumable": "#4BC0C0",
    //   "Sample Empty Order": "#9966FF",
    //   "Marketing Material": "#FF8C00",
    //   "B2C Distributor": "#7FFF00",
    //   "B2B Distributor": "#FFD700",
    //   "B2C Order": "#20B2AA",
    //   "B2B Pharmacy": "#BA55D3",
    // };
    const categories = Object.keys(orderCategoriesCount);
    const counts = Object.values(orderCategoriesCount);
    const categoryColors: { [category: string]: string } = {};
    categories.forEach((category) => {
      const randomColor = this.generateRandomColor();
      categoryColors[category] = randomColor;
    });
    const ctx = document.getElementById("donutChart") as HTMLCanvasElement;
    const existingChart = Chart.getChart(ctx);
    if (existingChart) {
      existingChart.destroy();
    }
    new Chart(ctx, {
      type: "doughnut",
      data: {
        labels: categories,
        datasets: [
          {
            data: counts,
            backgroundColor: categories.map(
              (category) => categoryColors[category]
            ),
          },
        ],
      },
      options: {
        responsive: false,
      },
    });
  }

  private generateRandomColor(): string {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  private createPieChartForSizes() {
    const orderSizeCount: { [size: string]: number } = {};
    this.orders.forEach((order) => {
      const size = order.orderSize;
      orderSizeCount[size] = (orderSizeCount[size] || 0) + 1;
    });
    const categoryColors: { [category: string]: string } = {
      "Small": "#4BC0C0",
      "Medium": "#9966FF",
      "Large": "#FF8C00",
      "Extra Large": "#7FFF00",
    };
    const sizes = Object.keys(orderSizeCount);
    const counts = Object.values(orderSizeCount);
    const ctx = document.getElementById("pieChart") as HTMLCanvasElement;
    const existingChart = Chart.getChart(ctx);
    if (existingChart) {
      existingChart.destroy();
    }
    new Chart(ctx, {
      type: "pie",
      data: {
        labels: sizes,
        datasets: [
          {
            data: counts,
            backgroundColor: sizes.map((size) => categoryColors[size]),
          },
        ],
      },
      options: {
        responsive: false,
      },
    });
  }

  private createBarChartForDispatchMedium() {
    // Destroy existing chart instance if it exists
    const ctx = document.getElementById("barChart") as HTMLCanvasElement;
    const existingChart = Chart.getChart(ctx);
    if (existingChart) {
      existingChart.destroy();
    }

    const busOrders = this.orders.filter(
      (order) => order.dispatchMedium === "Bus"
    );
    const localOrders = this.orders.filter(
      (order) => order.dispatchMedium === "Local"
    );

    const busCount = busOrders.length;
    const localCount = localOrders.length;

    // Create new chart instance
    new Chart(ctx, {
      type: "bar",
      data: {
        labels: ["Bus", "Local"],
        datasets: [
          {
            label: "Number of Orders",
            data: [busCount, localCount],
            backgroundColor: ["#FF6384", "#36A2EB"], // Adjust colors as needed
          },
        ],
      },
      options: {
        responsive: true,
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });
  }
  private createMultiLineChartForOrderStatus() {
    const orderStatusCounts: any = {
      CREATED: [],
      DISPATCHED: [],
      RECEIVED: [],
      DELIVERED: [],
      REJECTED: []
    };

    // Initialize count for each status
    const statusCounts: any = {
      CREATED: 0,
      DISPATCHED: 0,
      RECEIVED: 0,
      DELIVERED: 0,
      REJECTED: 0
    };

    this.orders.forEach((order: OrderResponseModel) => {
      const status: string = order.orderStatus;
      const createdAt: Date = new Date(order.createdAt);

      // Increment count for the corresponding status
      if (!orderStatusCounts[status]) {
        orderStatusCounts[status] = [];
      }
      statusCounts[status]++;

      // Push the updated count for each status type as the y-value
      Object.keys(statusCounts).forEach((key: string) => {
        orderStatusCounts[key].push({ x: createdAt, y: statusCounts[key] });
      });
    });

    const ctx: HTMLCanvasElement = document.getElementById(
      "lineChart"
    ) as HTMLCanvasElement;
    const existingChart = Chart.getChart(ctx);
    if (existingChart) {
      existingChart.destroy();
    }

    new Chart(ctx, {
      type: "line",
      data: {
        datasets: [
          {
            label: "CREATED",
            data: orderStatusCounts.CREATED,
            borderColor: "rgba(255, 99, 132, 1)",
            borderWidth: 2,
            fill: false,
          },
          {
            label: "DISPATCHED",
            data: orderStatusCounts.DISPATCHED,
            borderColor: "rgba(54, 162, 235, 1)",
            borderWidth: 2,
            fill: false,
          },
          {
            label: "RECEIVED",
            data: orderStatusCounts.RECEIVED,
            borderColor: "rgba(255, 206, 86, 1)",
            borderWidth: 2,
            fill: false,
          },
          {
            label: "DELIVERED",
            data: orderStatusCounts.DELIVERED,
            borderColor: "rgba(75, 192, 192, 1)",
            borderWidth: 2,
            fill: false,
          },
          {
            label: "REJECTED",
            data: orderStatusCounts.REJECTED,
            borderColor: "rgba(245, 40, 145, 0.8)",
            borderWidth: 2,
            fill: false,
          },
        ],
      },
      options: {
        responsive: false,
        scales: {
          x: {
            type: "time",
            time: {
              unit: "day",
            },
            ticks: {
              source: "auto",
              autoSkip: true,
            },
          },
          y: {
            beginAtZero: true,
            title: {
              display: true,
              text: "Count",
            },
          },
        },
      },
    });
  }
  render() {
    const userAccess = this.dmsDataService.getDMSData()?.user?.accessLevel;
    if(userAccess === AccessLevel.Admin || userAccess === AccessLevel.Super_Admin){
      return template.call(this);
    }
    else{
      return manageButtonTemplate.call(this);
    }
  }
}
