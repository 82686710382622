import { RIDERS_URL } from "../../constants/api-urls";

export class DMSRidersService {
  async getRidersData() {
    const token = localStorage.getItem("auth-token-dms");
    const response = await fetch(RIDERS_URL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    // Check if request was successful
    if (!response.ok) {
      const errorMessage = `An error occurred: ${response.statusText}`;
      throw new Error(errorMessage);
    }

    // Parse response JSON
    const responseData = await response.json();
    return responseData;
  }

  async create(riderName: string) {
    const token = localStorage.getItem("auth-token-dms");
    const response = await fetch(RIDERS_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify([{ riderName: riderName }]),
    });

    // Check if request was successful
    if (!response.ok) {
      const errorMessage = `An error occurred: ${response.statusText}`;
      throw new Error(errorMessage);
    }

    // Parse response JSON
    const responseData = await response.json();
    return responseData;
  }
  
  async deleteRider(
    riderId: string
  ) {
    const token = localStorage.getItem("auth-token-dms");
    const response = await fetch(`${RIDERS_URL}/${riderId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      const errorMessage = `An error occurred: ${response.statusText}`;
      throw new Error(errorMessage);
    }
    return response;
  }
}

