declare global {
  interface Window {
    dms: any;
  }
}

window.dms = window.dms || {};
window.dms.provider = window.dms.provider || {};
const store = window.dms.provider;

function requestService(key: string): any {
  const event = new CustomEvent("request-service", {
    detail: { key, service: undefined },
    bubbles: true,
    cancelable: true,
  });
  document.dispatchEvent(event);
  return event.detail.service;
}

function initializeProvider() {
  store._services = new Map();
  document.addEventListener("request-service", (event: any) => {
    const { key } = event.detail;
    if (store._services.has(key)) {
      event.detail.service = store._services.get(key);
      event.stopPropagation();
    }
  });
}

function provideService(key: string, service: any, shouldFreeze = true) {
  if (shouldFreeze) {
    Object.freeze(service);
  }
  store._services.set(key, service);
}

initializeProvider();

const SERVICE_PROVIDER_ID = {
  AUTH_SERVICE: "auth-service",
  EVENT_BUS: "event-bus",
  DMS_ROUTER: "dms-router",
  DMS_DATA_SERVICE: "dms-data-service",
  ORDER_SERVICE: "order-service",
  HUBS_SERVICE: "hubs-service",
  RIDERS_SERVICE: "riders-service",
  CATEGORY_SERVICE: "category-service"
};

export { requestService, provideService, SERVICE_PROVIDER_ID };
